import { useTranslation } from "react-i18next";
import PageHelment from "../components/common/Helment";
import Breadcrumb from "../components/header/Breadcrumb";
import CallAction from "../components/partial/CallAction";
import ActionButton, {
  ActionButtonA,
} from "../components/partial/ActionButton";
import banner20 from "../resources/banner/banner20.jpg";
import ContactMap from "../blocks/contact/ContactMap";
import ContactInfo from "../blocks/contact/ContactInfo";

const Contact = () => {
  const { t } = useTranslation();
  return (
    <>
      <PageHelment
        title={`${t("common.meta.prefix")} | ${t("contact.meta.title")} | ${t(
          "common.meta.suffix"
        )}`}
        description={t("contact.meta.description")}
      />
      <CallAction
        bgImage={<img src={banner20} alt={t("contact.meta.title")} />}
        className="ptb--100"
        textAlign="left"
        tagline={t("contact.banner.tagline")}
        h1={t("contact.banner.title")}
        description={t("contact.banner.description")}
        button={
          <ActionButtonA
            id="online_cs_2"
            href={`https://wpa.b.qq.com/cgi/wpa.php?ln=1&key=XzkzODA0NTYxOF80OTM2NDFfNDAwMTIwMDEwOF8yXw`}
            className="mt--40"
          >
            {t("contact.banner.button.text")}
          </ActionButtonA>
        }
      />
      <Breadcrumb parentPage={t("contact.breadcrumb.level.1")} />
      <ContactMap />
      <ContactInfo />
    </>
  );
};

export default Contact;
