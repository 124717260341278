import { useTranslation } from "react-i18next";
import PageHelment from "../components/common/Helment";
import CallAction from "../components/partial/CallAction";
import IntroDark from "../blocks/intro/IntroDark";
import Breadcrumb from "../components/header/Breadcrumb";
import banner06 from "../resources/banner/banner06.jpg";
import HomeAwards from "../blocks/home/HomeAwards";
import AboutHblAdvantages from "../blocks/about/AboutHblAdvantages";
import AboutHblProtection from "../blocks/about/AboutHblProtection";

const AboutHblAdv = () => {
  const { t } = useTranslation();

  return (
    <>
      <PageHelment
        title={`${t("common.meta.prefix")} | ${t(
          "aboutHblAdv.meta.title"
        )} | ${t("common.meta.suffix")}`}
        description={t("aboutHblAdv.meta.description")}
      />
      <CallAction
        bgImage={<img src={banner06} alt={t("aboutHblAdv.meta.title")} />}
        className="ptb--100"
        textAlign="left"
        tagline={t("aboutHblAdv.banner.tagline")}
        h1={t("aboutHblAdv.banner.title")}
        description={t("aboutHblAdv.banner.description")}
      />
      <Breadcrumb
        parentPage={t("aboutHblAdv.breadcrumb.level.1")}
        currentPage={t("aboutHblAdv.breadcrumb.level.2")}
      />

      <IntroDark
        title={t("aboutHblAdv.about.header")}
        description={t("aboutHblAdv.about.desc")}
      />
      <HomeAwards />
      <AboutHblAdvantages />
      <AboutHblProtection />
    </>
  );
};
export default AboutHblAdv;
