import { useState } from "react";
import { NavTabsProps } from "./NavTabs.type";
import { Link } from "react-router-dom";
import classnames from "classnames";

const NavTabs = (props: NavTabsProps) => {
  const [activeTab, setActiveTab] = useState(0);
  const handleNavClicked = (index: number) => {
    setActiveTab(index);
  };

  return (
    <div className="nav-tabs">
      <div className="nav-container">
        <div className="container-md gx-0 g-md-3">
          <ul className="nav row gx-0">
            {props.data.map((tab, i) => {
              const className = classnames("nav-link", {
                active: i === activeTab,
              });
              return (
                <li className="nav-item col" role="presentation" key={i}>
                  <Link
                    to="#"
                    className={className}
                    role="tab"
                    onClick={() => handleNavClicked(i)}
                  >
                    {tab.nav}
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
      </div>

      <div className="content-container">
        <div className="container-md gx-0 g-md-3">
          <div className="tab-pane ptb--160 pt_sm--0 pb_sm--75 pt_md--0 pb_md--75">
            {props.data.map((d, i) => {
              if (activeTab === i) {
                return <div key={i}>{d.content}</div>;
              }
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NavTabs;
