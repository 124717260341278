import { Icon } from "@ailibs/feather-react-ts";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as IconCustomerService } from "../../resources/svg/icon_customer_service.svg";
import { ReactComponent as IconCustomerService17 } from "../../resources/svg/icon_customer_service_17.svg";
import { ReactComponent as IconWhatsApp } from "../../resources/svg/icon_whatsapp.svg";
import { ReactComponent as IconQQ } from "../../resources/svg/icon_qq.svg";
import useOutsideClick from "../../hooks/useOutsideClick";
import useWindowSize from "../../hooks/useWindowSize";
import classNames from "classnames";

const HeaderNavDropDown = () => {
  const { t, i18n } = useTranslation();
  const [openMenu, setOpenMenu] = useState(false);
  const size = useWindowSize();

  const linkQQ =
    "https://wpa.b.qq.com/cgi/wpa.php?ln=1&key=XzkzODA0NTYxOF80OTM2NDFfNDAwMTIwMDEwOF8yXw";
  const lineWhatsApp = "https://wa.me/85263358564";

  const handleMenuOpen = () => {
    setOpenMenu(true);
  };

  const ref = useOutsideClick(() => setOpenMenu(false));

  // prevent bubbling
  const handleWrapperClick = (event: React.MouseEvent) => {
    event.stopPropagation();
  };

  return (
    <div className="dd-m-container">
      <div className="dd-m-toggle" onClick={handleMenuOpen} ref={ref}>
        <div className="dd-m-label">
          {size.innerWidth < 1800 && <IconCustomerService />}
          {size.innerWidth > 1799 && <IconCustomerService17 />}
          <span>{t("header.online-cs")}</span>
        </div>

        <Icon
          name={openMenu ? "chevron-up" : "chevron-down"}
          color="#fff"
          size={16}
        />
      </div>
      <div className={classNames("dd-m-menu", { open: openMenu })}>
        <div className="dd-m-item">
          <a href={lineWhatsApp} target="_blank" id="online_cs_2">
            <IconWhatsApp />
            <span className="dd-m-item-text">WhatsApp</span>
          </a>
        </div>
        <div className="dd-m-item">
          <a href={linkQQ} target="_blank" id="online_cs_1">
            <IconQQ />
            <span className="dd-m-item-text">QQ</span>
          </a>
        </div>
      </div>
    </div>
  );
};

export default HeaderNavDropDown;
