import { Trans, useTranslation } from "react-i18next";
import PageHelment from "../../components/common/Helment";
import SectionTitle from "../../components/partial/SectionTitle";
import { Link } from "react-router-dom";

const CookiePolicy = () => {
  const { t, i18n } = useTranslation();
  return (
    <>
      <PageHelment
        title={`${t("common.meta.prefix")} | ${t(
          "legal.cookies-policy.meta.title"
        )}`}
        description={t("legal.cookies-policy.meta.description")}
      />
      <div className="legal-content pt--130 pb--150 pt_sm--60 pb_sm--75 pt_md--60 pb_md--75 pt_lg--60 pb_lg--75">
        <div className="container">
          <div className="text-left text-sm-center mb--60">
            <SectionTitle title={t("legal.cookies-policy.page.title")} />
          </div>
          <section>
            <div className="mt--75 mt_sm--50 mt_md--50 mt_lg--50">
              <div>
                <p>{t("legal.cookies-policy.section.1.text.1")}</p>
                <p>{t("legal.cookies-policy.section.1.text.2")}</p>
                <p>{t("legal.cookies-policy.section.1.text.3")}</p>
              </div>
            </div>
          </section>
          {/*  */}
          <section>
            <div className="mt--75 mt_sm--50 mt_md--50 mt_lg--50">
              <h3>{t("legal.cookies-policy.section.2.header")}</h3>
              <div>
                <p>{t("legal.cookies-policy.section.2.text.1")}</p>
                <p>
                  <Trans
                    i18nKey="legal.cookies-policy.section.2.text.2"
                    components={{
                      italic: <i />,
                      bold: <strong />,
                      red: <span className="theme-color" />,
                      atag: (
                        <Link to={`/${i18n.language}/legal/privacy-policy`} />
                      ),
                    }}
                  />
                </p>
                <p>{t("legal.cookies-policy.section.2.text.4")}</p>
              </div>
            </div>
          </section>
          {/*  */}
          <section>
            <div className="mt--75 mt_sm--50 mt_md--50 mt_lg--50">
              <h3>{t("legal.cookies-policy.section.3.header")}</h3>
              <div className="mt--20">
                <h4>{t("legal.cookies-policy.section.3.text.1")}</h4>
                <p>{t("legal.cookies-policy.section.3.text.2")}</p>
                <h4>{t("legal.cookies-policy.section.3.text.3")}</h4>
                <p>{t("legal.cookies-policy.section.3.text.4")}</p>
                <h4>{t("legal.cookies-policy.section.3.text.5")}</h4>
                <p>{t("legal.cookies-policy.section.3.text.6")}</p>
                <h4>{t("legal.cookies-policy.section.3.text.7")}</h4>
                <p>{t("legal.cookies-policy.section.3.text.8")}</p>
                <p>{t("legal.cookies-policy.section.3.text.9")}</p>
                <h4>{t("legal.cookies-policy.section.3.text.10")}</h4>
                <p>{t("legal.cookies-policy.section.3.text.11")}</p>
              </div>
            </div>
          </section>
          {/*  */}
          <section>
            <div className="mt--75 mt_sm--50 mt_md--50 mt_lg--50">
              <h3>{t("legal.cookies-policy.section.4.header")}</h3>
              <div className="mt--75 mt_sm--50 mt_md--50 mt_lg--50">
                <table className="table">
                  <tbody>
                    <tr>
                      <th>{t("legal.cookies-policy.section.4.text.1")}</th>
                      <td>
                        {t("legal.cookies-policy.section.4.text.2")}
                        <br />
                        {t("legal.cookies-policy.section.4.text.3")}
                        <br />
                        {t("legal.cookies-policy.section.4.text.4")}
                      </td>
                    </tr>
                    <tr>
                      <th>{t("legal.cookies-policy.section.4.text.5")}</th>
                      <td>{t("legal.cookies-policy.section.4.text.6")}</td>
                    </tr>
                    <tr>
                      <th>{t("legal.cookies-policy.section.4.text.7")}</th>
                      <td>{t("legal.cookies-policy.section.4.text.8")}</td>
                    </tr>
                    <tr>
                      <th>{t("legal.cookies-policy.section.4.text.9")}</th>
                      <td>{t("legal.cookies-policy.section.4.text.10")}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              {/* table 2 */}
              <div className="mt--75 mt_sm--50 mt_md--50 mt_lg--50">
                <table className="table">
                  <tbody>
                    <tr>
                      <th>{t("legal.cookies-policy.section.4.text.11")}</th>
                      <td>
                        {t("legal.cookies-policy.section.4.text.12")}
                        <br />
                        {t("legal.cookies-policy.section.4.text.13")}
                        <br />
                        {t("legal.cookies-policy.section.4.text.14")}
                      </td>
                    </tr>
                    <tr>
                      <th>{t("legal.cookies-policy.section.4.text.15")}</th>
                      <td>{t("legal.cookies-policy.section.4.text.16")}</td>
                    </tr>
                    <tr>
                      <th>{t("legal.cookies-policy.section.4.text.17")}</th>
                      <td>{t("legal.cookies-policy.section.4.text.18")}</td>
                    </tr>
                    <tr>
                      <th>{t("legal.cookies-policy.section.4.text.19")}</th>
                      <td>
                        {t("legal.cookies-policy.section.4.text.20")}
                        <br />
                        {t("legal.cookies-policy.section.4.text.21")}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              {/* table 3 */}
              <div className="mt--75 mt_sm--50 mt_md--50 mt_lg--50">
                <table className="table">
                  <tbody>
                    <tr>
                      <th>{t("legal.cookies-policy.section.4.text.22")}</th>
                      <td>
                        {t("legal.cookies-policy.section.4.text.23")}
                        <br />
                        {t("legal.cookies-policy.section.4.text.24")}
                        <br />
                        {t("legal.cookies-policy.section.4.text.25")}
                      </td>
                    </tr>
                    <tr>
                      <th>{t("legal.cookies-policy.section.4.text.26")}</th>
                      <td>{t("legal.cookies-policy.section.4.text.27")}</td>
                    </tr>
                    <tr>
                      <th>{t("legal.cookies-policy.section.4.text.28")}</th>
                      <td>{t("legal.cookies-policy.section.4.text.29")}</td>
                    </tr>
                    <tr>
                      <th>{t("legal.cookies-policy.section.4.text.30")}</th>
                      <td>{t("legal.cookies-policy.section.4.text.31")}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </section>
          {/*  */}
          <section>
            <div className="mt--75 mt_sm--50 mt_md--50 mt_lg--50">
              <h3>{t("legal.cookies-policy.section.5.header")}</h3>
              <div className="mt--20">
                <p>{t("legal.cookies-policy.section.5.text.1")}</p>
                <h4>{t("legal.cookies-policy.section.5.text.2")}</h4>
                <p>{t("legal.cookies-policy.section.5.text.3")}</p>
                <h4>{t("legal.cookies-policy.section.5.text.4")}</h4>
                <p>{t("legal.cookies-policy.section.5.text.5")}</p>
                {/* <h4>{t("legal.cookies-policy.section.5.text.6")}</h4>
                <p>{t("legal.cookies-policy.section.5.text.7")}</p> */}
                <h4>{t("legal.cookies-policy.section.5.text.8")}</h4>
                <p>{t("legal.cookies-policy.section.5.text.9")}</p>
                <h4>{t("legal.cookies-policy.section.5.text.10")}</h4>
                <p>{t("legal.cookies-policy.section.5.text.11")}</p>
                <p>{t("legal.cookies-policy.section.5.text.12")}</p>
                <p>{t("legal.cookies-policy.section.5.text.13")}</p>
              </div>
            </div>
          </section>
          {/*  */}
          <section>
            <div className="mt--75 mt_sm--50 mt_md--50 mt_lg--50">
              <h3>{t("legal.cookies-policy.section.6.header")}</h3>
              <div className="mt--20">
                <p>{t("legal.cookies-policy.section.6.text.1")}</p>
                <p>{t("legal.cookies-policy.section.6.text.2")}</p>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default CookiePolicy;
