import { ReactComponent as IconSectionTitle } from "../../resources/svg/icon_section_title.svg";

interface SectionTitleProps {
  title: string;
  onClick?: () => void;
}

const SectionTitle = (props: SectionTitleProps) => {
  return (
    <div className="section-title" onClick={props.onClick}>
      <IconSectionTitle />
      <h3>{props.title}</h3>
    </div>
  );
};

export default SectionTitle;
