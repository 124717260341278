import { Icon } from "@ailibs/feather-react-ts";
import cn from "classnames";
import { onlyLettersAndSpaces } from "../../helpers";

interface BreadcrumbProps {
  parentPage?: string;
  currentPage?: string;
}

const Breadcrumb = (props: BreadcrumbProps) => {
  const currentClassNames = cn({
    "english-only": onlyLettersAndSpaces(props.currentPage!),
  });
  return (
    <div className="breadcrumb-bar text-white ptb--20 ptb_md--10">
      <div className="container">
        <div className="d-flex justify-content-start align-items-center">
          <span>{props.parentPage}</span>
          {props.currentPage && (
            <span className="plr--15 plr_md--10">
              <Icon name="chevron-right" color="#fff" size={20} />
            </span>
          )}
          <span className={currentClassNames}>
            <strong>{props.currentPage}</strong>
          </span>
        </div>
      </div>
    </div>
  );
};

export default Breadcrumb;
