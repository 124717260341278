import { useTranslation } from "react-i18next";
import PageHelment from "../components/common/Helment";
import CallAction from "../components/partial/CallAction";
import banner05 from "../resources/banner/banner05.jpg";
import Breadcrumb from "../components/header/Breadcrumb";
import IntroDark from "../blocks/intro/IntroDark";
import AboutHblContent from "../blocks/about/AboutHblContent";

const AboutHBL = () => {
  const { t } = useTranslation();
  return (
    <>
      <PageHelment
        title={`${t("common.meta.prefix")} | ${t("aboutHBL.meta.title")} | ${t(
          "common.meta.suffix"
        )}`}
        description={t("aboutHBL.meta.description")}
      />
      <CallAction
        bgImage={<img src={banner05} alt={t("aboutHBL.meta.title")} />}
        className="ptb--100"
        textAlign="left"
        tagline={t("aboutHBL.banner.tagline")}
        h1={t("aboutHBL.banner.title")}
        description={t("aboutHBL.banner.description")}
      />
      <Breadcrumb
        parentPage={t("aboutHBL.breadcrumb.level.1")}
        currentPage={t("aboutHBL.breadcrumb.level.2")}
      />

      <IntroDark
        title={t("aboutHBL.about.header")}
        description={t("aboutHBL.about.desc")}
      ></IntroDark>
      <AboutHblContent />
    </>
  );
};

export default AboutHBL;
