import { useTranslation } from "react-i18next";
import bg30 from "../resources/background/bg30.png";
import PageHelment from "../components/common/Helment";
import Breadcrumb from "../components/header/Breadcrumb";
import CallAction from "../components/partial/CallAction";
import AnnouncementDetail from "../blocks/announcement/AnnouncementDetail";
import useFetch from "use-http";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { LANGUAGES } from "../constants";
import NewsDetail from "../blocks/news/NewsDetail";
import { isNumeric } from "../helpers";

const NewsDetails = () => {
  const { t, i18n } = useTranslation();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const params = useParams();
  const id = params.id;

  const { get, post, response, loading, error } = useFetch(
    process.env.REACT_APP_BACKEND_API_URL
  );
  const [newsData, setNewsData] = useState<any>([]);
  // get single news function
  const getNews = async () => {
    // get the language short code
    const languageCode = i18n.language === "tc" ? "zh_HK" : "zh_CN";

    const results = await get(`/dj-news-list/${languageCode}`);

    if (response.ok) setNewsData(results);
  };

  // check id once page loaded
  useEffect(() => {
    if (id === undefined || +id < 0 || !isNumeric(id)) {
      errorRedirect();
    } else {
      getNews();
    }
  }, [pathname]);

  // handle of news not found in list
  useEffect(() => {
    if (newsData.length && !newsData.at(id)) {
      errorRedirect();
    }
  }, [newsData]);

  // handle fetch error
  useEffect(() => {
    if (error) {
      errorRedirect();
    }
  }, [error]);

  const errorRedirect = () => navigate(`/${i18n.language}`);

  return (
    <>
      {newsData.length && newsData.at(id) && (
        <>
          <PageHelment
            title={`${t("common.meta.prefix")} | ${newsData.at(id).title}`}
          />
          <CallAction
            bgImage={<img src={bg30} alt={newsData.at(id).title} />}
            className="ptb--100"
            textAlign="left"
            h1={t("djnews.banner.title")}
          />
          <Breadcrumb
            parentPage={t("djnews.breadcrumb.level.1")}
            currentPage={newsData.at(id).title}
          />
          <NewsDetail newsList={newsData} id={+id!} />
        </>
      )}
    </>
  );
};

export default NewsDetails;
