import { useTranslation } from "react-i18next";
import IntroDark from "../blocks/intro/IntroDark";
import PageHelment from "../components/common/Helment";
import CallAction from "../components/partial/CallAction";
import banner09 from "../resources/banner/banner09.jpg";
import Breadcrumb from "../components/header/Breadcrumb";
import TradeTabs from "../blocks/trade/TradeTabs";

const TradeRules = () => {
  const { t } = useTranslation();
  return (
    <>
      <PageHelment
        title={`${t("common.meta.prefix")} | ${t("trade.meta.title")} | ${t(
          "common.meta.suffix"
        )}`}
        description={t("trade.meta.description")}
      />
      <CallAction
        bgImage={<img src={banner09} alt={t("trade.meta.title")} />}
        className="ptb--100"
        textAlign="left"
        tagline={t("trade.banner.tagline")}
        h1={t("trade.banner.title")}
        description={t("trade.banner.description")}
      />
      <Breadcrumb
        parentPage={t("trade.breadcrumb.level.1")}
        currentPage={t("trade.breadcrumb.level.2")}
      />

      <IntroDark
        title={t("trade.intro.header")}
        description={t("trade.intro.desc")}
      />
      <TradeTabs />
    </>
  );
};

export default TradeRules;
