import { useTranslation } from "react-i18next";
import SectionTitle from "../../components/partial/SectionTitle";
import { useEffect, useRef, useState } from "react";
import ActionButton from "../../components/partial/ActionButton";
import classNames from "classnames";
import { isValidEmail } from "../../helpers";
import { LEAD_CREATE_API_URL } from "../../constants";
import { ReactComponent as FormSuccessIcon } from "../../resources/svg/icon_form_completed.svg";

type FieldTypes = {
  fullname?: string;
  email?: string;
  mobile?: string;
  submit?: string;
};

const DemoAccountForm = () => {
  const { t, i18n } = useTranslation();
  const formRef = useRef<HTMLFormElement>(null);
  const [formValues, setFormValues] = useState<FieldTypes>({
    fullname: "",
    email: "",
    mobile: "",
  });
  const [errors, setErrors] = useState<FieldTypes>({});
  const [submitting, setSubmitting] = useState(false);
  const [message, setMessage] = useState("");
  const [showResults, setShowResults] = useState(false);

  // 1. event on submit
  const onSubmit = () => {
    // console.log(formValues);
    setErrors(validateValues(formValues));
    setSubmitting(true);
  };

  // 2. do the validation
  const validateValues = (inputValues: FieldTypes) => {
    let errors: FieldTypes = {};
    if (inputValues.fullname?.length! < 1) {
      errors.fullname = t("demoacc.form.message.error.name");
    }
    if (inputValues.email?.length! < 1) {
      errors.email = t("demoacc.form.message.error.email");
    }
    if (!isValidEmail(inputValues.email!)) {
      errors.email = t("demoacc.form.message.error.email");
    }
    if (inputValues.mobile?.length! < 8) {
      errors.mobile = t("demoacc.form.message.error.mobile");
    }
    return errors;
  };

  // 3. trigger submit action
  useEffect(() => {
    if (Object.keys(errors).length === 0 && submitting) {
      finishSubmit();
    } else {
      // console.log(errors);
    }
  }, [errors]);

  // 4. the submit action
  const finishSubmit = async () => {
    console.log("sumbit to hantecgroup");

    // =====================
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    const raw = JSON.stringify({
      campaignId: 9,
      name: formValues.fullname,
      lang: i18n.language === "tc" ? "zh-HK" : "zh-CN",
      contactNo: formValues.mobile,
      email: formValues.email,
    });
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
    };
    const endpoint = LEAD_CREATE_API_URL;
    try {
      await fetch(endpoint, requestOptions)
        .then((response) => response.json())
        .then((response) => {
          if (response.status === "success") {
            // set message
            setMessage(t("demoacc.form.message.success.text"));
            // show pop up
            setShowResults(true);
            // reset form
            if (formRef.current) formRef.current.reset();
          } else {
            console.info(response);
            // get default error message from response
            let errorMessage = response.msg;
            // handle on duplicate email
            if (response.msg === "error.emailExist") {
              errorMessage = t("demoacc.form.message.error.email-duplicated");
            }
            // set error
            setErrors({ submit: errorMessage });
            // set message
            setMessage(t("demoacc.form.message.fail.text"));
            // show pop up
            setShowResults(true);
          }
        });
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div className="demo-account-form bg-style--3_ bg_image ptb--150 pt_sm--60 pb_sm--75 pt_md--60 pb_md--75">
      <div className="container">
        {showResults ? (
          <div className="form-results-container">
            <div className="row">
              <div className="col-12 mb--60 mb_sm--60 mb_md--60 mb_lg--60">
                <SectionTitle title={t("demoacc.form.header")} />
              </div>
            </div>

            <div className="row">
              <div className="col-12 col-md-4 col-lg-12 text-center text-md-start text-lg-center">
                <div className="icon-wrapper d-inline-block">
                  <FormSuccessIcon />
                </div>
              </div>
              <div className="col-12 col-md-8 col-lg-12 text-center text-md-start text-lg-center">
                <h3 className="mt--50 mt_md--10">{message}</h3>
                {errors.submit && <p>{errors.submit}</p>}
                <div className="wrapper mt--50">
                  <ActionButton
                    className="light"
                    to=""
                    onClick={() => {
                      formRef.current?.reset();
                      setShowResults(false);
                    }}
                  >
                    {t("demoacc.form.message.home.button.text")}
                  </ActionButton>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <form ref={formRef}>
            <div className="row justify-content-center justify-content-xl-start">
              <div className="col-12 col-md-12 col-xl-4 mb_sm--60 mb_md--60 mb_lg--60">
                <SectionTitle title={t("demoacc.form.header")} />
              </div>
              <div className="col-12 col-md-6 col-xl-6">
                {/*  */}
                <div
                  className={classNames("rn-form-group mb--60", {
                    "has-error": errors.fullname,
                  })}
                >
                  <input
                    type="text"
                    name="fullname"
                    id="fullnameInput"
                    placeholder={t("demoacc.form.input.name")}
                    required={false}
                    onChange={(e) => {
                      formValues.fullname = e.currentTarget.value;
                      setFormValues(formValues);
                    }}
                  />
                  <label htmlFor="fullnameInput">
                    {t("demoacc.form.input.name")}
                  </label>
                  <p
                    className={classNames("error-msg", {
                      show: errors.fullname,
                    })}
                  >
                    {errors.fullname}
                  </p>
                </div>
                {/*  */}
                <div
                  className={classNames("rn-form-group mb--60", {
                    "has-error": errors.email,
                  })}
                >
                  <input
                    type="text"
                    name="email"
                    id="emailInput"
                    placeholder={t("demoacc.form.input.email")}
                    required={false}
                    onChange={(e) => {
                      formValues.email = e.currentTarget.value;
                      setFormValues(formValues);
                    }}
                  />
                  <label htmlFor="emailInput">
                    {t("demoacc.form.input.email")}
                  </label>
                  <p
                    className={classNames("error-msg", {
                      show: errors.email,
                    })}
                  >
                    {errors.email}
                  </p>
                </div>

                {/*  */}
                <div
                  className={classNames("rn-form-group mb--60", {
                    "has-error": errors.mobile,
                  })}
                >
                  <input
                    type="text"
                    name="mobile"
                    id="mobileInput"
                    placeholder={t("demoacc.form.input.mobile")}
                    required={false}
                    onChange={(e) => {
                      formValues.mobile = e.currentTarget.value;
                      setFormValues(formValues);
                    }}
                  />
                  <label htmlFor="mobileInput">
                    {t("demoacc.form.input.mobile")}
                  </label>
                  <p
                    className={classNames("error-msg", {
                      show: errors.mobile,
                    })}
                  >
                    {errors.mobile}
                  </p>
                </div>
              </div>

              <div className="col-12 col-md-12 col-xl-4"></div>
            </div>
            <div className="row justify-content-center">
              <div className="col-12 col-md-4 col-lg-3 col-xl-4">
                <div className="text-sm-center text-lg-start">
                  <ActionButton className="light" to="#" onClick={onSubmit}>
                    {t("demoacc.form.button.text")}
                  </ActionButton>
                </div>
              </div>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default DemoAccountForm;
