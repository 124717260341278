import { useTranslation } from "react-i18next";
import PageHelment from "../components/common/Helment";
import Breadcrumb from "../components/header/Breadcrumb";
import CallAction from "../components/partial/CallAction";
import banner18 from "../resources/banner/banner18.jpg";
import ActionButton from "../components/partial/ActionButton";
import IntroDark from "../blocks/intro/IntroDark";
import IbServices from "../blocks/ibsw/IbServices";
import IbContent from "../blocks/ibsw/IbContent";

const IB = () => {
  const { t } = useTranslation();
  return (
    <>
      <PageHelment
        title={`${t("common.meta.prefix")} | ${t("IB.meta.title")} | ${t(
          "common.meta.suffix"
        )}`}
        description={t("IB.meta.description")}
      />
      <CallAction
        bgImage={<img src={banner18} alt={t("IB.meta.title")} />}
        className="ptb--100"
        textAlign="left"
        tagline={t("IB.banner.tagline")}
        h1={t("IB.banner.title")}
        description={t("IB.banner.description")}
        button={
          <ActionButton target="_blank" to={`/ib-acc-open/opening-profile`}>
            {t("IB.banner.button.text")}
          </ActionButton>
        }
      />
      <Breadcrumb parentPage={t("IB.breadcrumb.level.1")} />
      <IntroDark
        title={t("IB.intro.header")}
        description={t("IB.intro.description")}
      />
      <IbServices />
      <IbContent />
    </>
  );
};

export default IB;
