import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import useFetch from "use-http";
import ActionButton from "../../components/partial/ActionButton";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ReactComponent as IconShare } from "../../resources/svg/icon_share.svg";
import { ReactComponent as IconShareRed } from "../../resources/svg/icon_share_red.svg";
import { LANGUAGES } from "../../constants";
import { NewsDetailProps } from "./NewsDetail.type";
import { htmlDecode } from "../../helpers";

const NewsDetail = (props: NewsDetailProps) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const news = props.newsList.at(props.id);

  /**
   * news data
   */

  // useEffect(() => {
  //   if (error) {
  //     navigate(`/${i18n.language}/announcement/`);
  //   }
  // }, [error]);

  // copy to clipboard
  const [copySuccess, setCopySuccess] = useState(false);
  const copyToClip = async () => {
    await navigator.clipboard.writeText(location.href);
    setCopySuccess(true);
    setTimeout(() => {
      setCopySuccess(false);
    }, 3000);
  };

  return (
    <div className="djnews-wrapper ptb--145 ptb_sm--50 ptb_md--75 ptb_lg--75">
      <div className="container">
        <div className="content-wrapper d-flex d-sm-block">
          {news && (
            <>
              {/* the title */}
              <h2 className="mb--25 mb_sm--0">{news.title}</h2>

              {/* the meta */}
              <div className="djnews-meta mt--25 mb--80 mt_sm--25 mb_sm--30 mt_md--50 mb_md--50">
                <div className="djnews-date">{news.formatDate}</div>
                <div className="djnews-share">
                  <span
                    className="djnews-share-button"
                    onClick={() => copyToClip()}
                  >
                    {copySuccess ? <IconShareRed /> : <IconShare />}
                  </span>
                  {copySuccess && (
                    <span className="djnews-share-button-result">
                      {t("common.button.link-copied")}
                    </span>
                  )}
                </div>
              </div>

              {/* the content */}
              <div className="djnews-content mt--80 mb--80 mt_sm--0 mb_sm--50 mt_md--50 mb_md--50 mt_lg--75 mb_lg--75">
                {news.description && (
                  <div
                    className="html"
                    dangerouslySetInnerHTML={{
                      __html: htmlDecode(news.description),
                    }}
                  />
                )}
              </div>

              {/* the footer */}
              <div className="djnews-footer mt--90 mb--75 mt_sm--0 mb_sm--0 mt_md--50 mb_md--25">
                <div className="djnews-footer-back">
                  <ActionButton className="light" to={`/${i18n.language}`}>
                    {t("djnews.detail.footer.back")}
                  </ActionButton>
                </div>
                <div className="djnews-footer-next">
                  {props.newsList.at(props.id + 1) && (
                    <ActionButton
                      className="light"
                      to={`/${i18n.language}/djnews/${props.id + 1}`}
                    >
                      {t("djnews.detail.footer.next")}
                    </ActionButton>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default NewsDetail;
