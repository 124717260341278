import { useTranslation } from "react-i18next";
import award1 from "../../resources/awards/award01.png";
import award2 from "../../resources/awards/award02.png";
import award3 from "../../resources/awards/award03.png";

import { ReactComponent as Icon01 } from "../../resources/svg/ib_adv_01.svg";
import { ReactComponent as Icon02 } from "../../resources/svg/ib_adv_02.svg";
import { ReactComponent as Icon03 } from "../../resources/svg/ib_adv_03.svg";
import { ReactComponent as Icon04 } from "../../resources/svg/ib_adv_04.svg";
import { ReactComponent as Icon05 } from "../../resources/svg/ib_adv_05.svg";

import service01 from "../../resources/ibsw/Hantec_graphic_01-21.png";
import service02 from "../../resources/ibsw/Hantec_graphic_01-22.png";
import service03 from "../../resources/ibsw/Hantec_graphic_01-23.png";
import service04 from "../../resources/ibsw/Hantec_graphic_01-24.png";
import service05 from "../../resources/ibsw/Hantec_graphic_01-25.png";

import ListLogo from "../../components/partial/ListLogo";

const IbServices = () => {
  const { t } = useTranslation();
  const list = Array(5)
    .fill("")
    .map((e, i) => {
      let icon;
      switch (i) {
        case 0:
          icon = <img src={service01} alt={t(`IB.intro.adv.desc.${i + 1}`)} />;
          break;
        case 1:
          icon = <img src={service02} alt={t(`IB.intro.adv.desc.${i + 1}`)} />;
          break;
        case 2:
          icon = <img src={service03} alt={t(`IB.intro.adv.desc.${i + 1}`)} />;
          break;
        case 3:
          icon = <img src={service04} alt={t(`IB.intro.adv.desc.${i + 1}`)} />;
          break;
        case 4:
          icon = <img src={service05} alt={t(`IB.intro.adv.desc.${i + 1}`)} />;
          break;
        default:
          icon = <img src={service01} alt={t(`IB.intro.adv.desc.${i + 1}`)} />;
          break;
      }

      return {
        icon: icon,
        title: t(`IB.intro.adv.desc.${i + 1}`),
      };
    });

  return (
    <div className="ib-services pb--150 pb_sm--75 pb_md--75">
      <div className="container">
        <div className="row justify-content-md-center">
          <ListLogo items={list} col="col-xl col-lg-4 col-md-4 col-6" />
        </div>
      </div>
    </div>
  );
};

export default IbServices;
