import { ContentImageRowProps } from "./ContentImageRow.type";
import cn from "classnames";

export const ContentImageRow = (props: ContentImageRowProps) => {
  const ImageColumn = (
    <div className="col-lg-6 col-12">
      <div className="thumb">
        <img className="w-100" src={props.imageSrc} alt="Service Images" />
      </div>
    </div>
  );

  const ContentColumn = (
    <div className="col-lg-6 col-12">
      <div className="details">{props.children}</div>
    </div>
  );

  return (
    <div className="container">
      <div className="row ptb--75 align-items-center">
        {ImageColumn}
        {ContentColumn}
      </div>
    </div>
  );
};

export const ContentImageRowFullWidth = (props: ContentImageRowProps) => {
  const { isHalfWidth = false } = props;
  const colClassNames = cn("col-12", isHalfWidth ? "col-lg-5" : "col-lg-7");
  const ContentColumn = (
    <div className={colClassNames}>
      <div className="details text-md-start">{props.children}</div>
    </div>
  );
  return (
    <div
      className={cn(
        `full-width-image-container full-width-image-container--${props.imagePosition}`,
        {
          "is-half-width": isHalfWidth,
        }
      )}
    >
      <div
        className={cn(
          `background-wrapper background-wrapper--${props.imagePosition}`,
          {
            "is-half-width": isHalfWidth,
          }
        )}
        style={{ backgroundImage: `url(${props.imageSrc})` }}
      ></div>
      <div className="container">
        <div className="row ptb--75 pt_sm--60 pt_md--60 align-items-center height-wrapper">
          {ContentColumn}
        </div>
      </div>
    </div>
  );
};
