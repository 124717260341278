import { useTranslation } from "react-i18next";
import NavTabs from "../../components/partial/NavTabs";
import { NavTab } from "../../components/partial/NavTabs.type";
import SectionTitle from "../../components/partial/SectionTitle";

const TradeTabs = () => {
  const { t } = useTranslation();
  const content1 = (
    <div className="container">
      <div className="row mb--60 mt_sm--60 mb_sm--50">
        <div className="col-lg-12">
          <div className="text-left">
            <SectionTitle title={t("trade.nav-tabs.title.1")} />
            <p className="mt--20 mt_sm--10">
              {t("trade.nav-tabs.description.1")}
            </p>
          </div>
        </div>
      </div>
      <table className="table">
        <thead className="">
          <tr className="">
            <th className="">{t("trade.nav-tabs.content.1.1")}</th>
            <th className="">{t("trade.nav-tabs.content.1.2")}</th>
            <th className="">{t("trade.nav-tabs.content.1.3")}</th>
          </tr>
        </thead>
        <tbody className="">
          <tr className="">
            <th className="">{t("trade.nav-tabs.content.1.4")}</th>
            <td className="">{t("trade.nav-tabs.content.1.5")}</td>
            <td className="">{t("trade.nav-tabs.content.1.6")}</td>
          </tr>
          <tr className="">
            <th className="">{t("trade.nav-tabs.content.1.7")}</th>
            <td className="" colSpan={2}>
              {t("trade.nav-tabs.content.1.8")}
            </td>
          </tr>
          <tr className="">
            <th className="">{t("trade.nav-tabs.content.1.9")}</th>
            <td className="" colSpan={2}>
              <p>{t("trade.nav-tabs.content.1.10")}</p>
              <p>{t("trade.nav-tabs.content.1.11")}</p>
              <p>{t("trade.nav-tabs.content.1.12")}</p>
              <p>{t("trade.nav-tabs.content.1.13")}</p>
              <p>{t("trade.nav-tabs.content.1.14")}</p>
              <p>{t("trade.nav-tabs.content.1.15")}</p>
            </td>
          </tr>
          <tr className="">
            <th className="">{t("trade.nav-tabs.content.1.16")}</th>
            <td className="">
              <p>{t("trade.nav-tabs.content.1.17")}</p>
              <p>{t("trade.nav-tabs.content.1.18")}</p>
            </td>
            <td className="">
              <p>{t("trade.nav-tabs.content.1.19")}</p>
              <p>{t("trade.nav-tabs.content.1.20")}</p>
            </td>
          </tr>
          <tr className="">
            <th className="">{t("trade.nav-tabs.content.1.21")}</th>
            <td className="">
              <p>{t("trade.nav-tabs.content.1.22")}</p>
              <p>{t("trade.nav-tabs.content.1.23")}</p>
              <p>{t("trade.nav-tabs.content.1.24")}</p>
            </td>
            <td className="">
              <p>{t("trade.nav-tabs.content.1.25")}</p>
              <p>{t("trade.nav-tabs.content.1.26")}</p>
              <p>{t("trade.nav-tabs.content.1.27")}</p>
            </td>
          </tr>
          <tr className="">
            <th className="">{t("trade.nav-tabs.content.1.28")}</th>
            <td className="" colSpan={2}>
              {t("trade.nav-tabs.content.1.29")}
            </td>
          </tr>
          <tr className="">
            <th className="">{t("trade.nav-tabs.content.1.30")}</th>
            <td className="" colSpan={2}>
              {t("trade.nav-tabs.content.1.31")}
            </td>
          </tr>
          <tr className="">
            <th className="">{t("trade.nav-tabs.content.1.32")}</th>
            <td className="" colSpan={2}>
              {t("trade.nav-tabs.content.1.33")}
            </td>
          </tr>
          <tr className="">
            <th className="">{t("trade.nav-tabs.content.1.34")}</th>
            <td className="" colSpan={2}>
              {t("trade.nav-tabs.content.1.35")}
            </td>
          </tr>
          <tr className="">
            <th className="">{t("trade.nav-tabs.content.1.36")}</th>
            <td className="" colSpan={2}>
              {t("trade.nav-tabs.content.1.37")}
            </td>
          </tr>
          <tr className="">
            <th className="">{t("trade.nav-tabs.content.1.38")}</th>
            <td className="">{t("trade.nav-tabs.content.1.39")}</td>
            <td className="">{t("trade.nav-tabs.content.1.40")}</td>
          </tr>
          <tr className="">
            <th className="">{t("trade.nav-tabs.content.1.41")}</th>
            <td className="" colSpan={2}>
              {t("trade.nav-tabs.content.1.42")}
            </td>
          </tr>
          <tr className="">
            <th className="">{t("trade.nav-tabs.content.1.43")}</th>
            <td className="" colSpan={2}>
              <p style={{ color: "#E21F26" }}>
                {t("trade.nav-tabs.content.1.44")}
              </p>
              <p style={{ color: "#E21F26" }}>
                {t("trade.nav-tabs.content.1.45")}
              </p>
            </td>
          </tr>
        </tbody>
      </table>

      <div className="row text-remarks">
        <div className="col-lg-12">
          <p>{t("trade.nav-tabs.content.1.46")}</p>
          <p>{t("trade.nav-tabs.content.1.47")}</p>
          <p>{t("trade.nav-tabs.content.1.48")}</p>
          <p>{t("trade.nav-tabs.content.1.49")}</p>
          <p>{t("trade.nav-tabs.content.1.50")}</p>
          <p>{t("trade.nav-tabs.content.1.51")}</p>
          <p>{t("trade.nav-tabs.content.1.52")}</p>
          <p>{t("trade.nav-tabs.content.1.53")}</p>
          <p>{t("trade.nav-tabs.content.1.54")}</p>
        </div>
      </div>
    </div>
  );
  const content2 = (
    <div className="container">
      <div className="row mb--60 mt_sm--60 mb_sm--50">
        <div className="col-lg-12">
          <div className="text-left">
            <SectionTitle title={t("trade.nav-tabs.title.2")} />
            <p className="mt--20 mt_sm--10">
              {t("trade.nav-tabs.description.2")}
            </p>
          </div>
        </div>
      </div>
      <table className="table">
        <thead className="">
          <tr className="">
            <th className="">{t("trade.nav-tabs.content.2.1")}</th>
            <th className="">{t("trade.nav-tabs.content.2.2")}</th>
            <th className="">{t("trade.nav-tabs.content.2.3")}</th>
          </tr>
        </thead>
        <tbody className="">
          <tr className="">
            <th className="">{t("trade.nav-tabs.content.2.4")}</th>
            <td className="">{t("trade.nav-tabs.content.2.5")}</td>
            <td className="">{t("trade.nav-tabs.content.2.6")}</td>
          </tr>
          <tr className="">
            <th className="">{t("trade.nav-tabs.content.2.7")}</th>
            <td className="" colSpan={2}>
              {t("trade.nav-tabs.content.2.8")}
            </td>
          </tr>
          <tr className="">
            <th className="">{t("trade.nav-tabs.content.2.9")}</th>
            <td className="" colSpan={2}>
              <p>{t("trade.nav-tabs.content.2.10")}</p>
              <p>{t("trade.nav-tabs.content.2.11")}</p>
              <p>{t("trade.nav-tabs.content.2.12")}</p>
              <p>{t("trade.nav-tabs.content.2.13")}</p>
              <p>{t("trade.nav-tabs.content.2.14")}</p>
              <p>{t("trade.nav-tabs.content.2.15")}</p>
            </td>
          </tr>
          <tr className="">
            <th className="">{t("trade.nav-tabs.content.2.16")}</th>
            <td className="">
              <p>{t("trade.nav-tabs.content.2.17")}</p>
              <p>{t("trade.nav-tabs.content.2.18")}</p>
            </td>
            <td className="">
              <p>{t("trade.nav-tabs.content.2.19")}</p>
              <p>{t("trade.nav-tabs.content.2.20")}</p>
            </td>
          </tr>
          <tr className="">
            <th className="">{t("trade.nav-tabs.content.2.21")}</th>
            <td className="">
              <p>{t("trade.nav-tabs.content.2.22")}</p>
              <p>{t("trade.nav-tabs.content.2.23")}</p>
              <p>{t("trade.nav-tabs.content.2.24")}</p>
            </td>
            <td className="">
              <p>{t("trade.nav-tabs.content.2.25")}</p>
              <p>{t("trade.nav-tabs.content.2.26")}</p>
              <p>{t("trade.nav-tabs.content.2.27")}</p>
            </td>
          </tr>
          <tr className="">
            <th className="">{t("trade.nav-tabs.content.2.28")}</th>
            <td className="" colSpan={2}>
              {t("trade.nav-tabs.content.2.29")}
            </td>
          </tr>
          <tr className="">
            <th className="">{t("trade.nav-tabs.content.2.31")}</th>
            <td className="" colSpan={2}>
              {t("trade.nav-tabs.content.2.32")}
            </td>
          </tr>
          <tr className="">
            <th className="">{t("trade.nav-tabs.content.2.33")}</th>
            <td className="">{t("trade.nav-tabs.content.2.34")}</td>
            <td className="">{t("trade.nav-tabs.content.2.35")}</td>
          </tr>
          <tr className="">
            <th className="">{t("trade.nav-tabs.content.2.36")}</th>
            <td className="" colSpan={2}>
              {t("trade.nav-tabs.content.2.37")}
            </td>
          </tr>
        </tbody>
      </table>

      <div className="row text-remarks">
        <div className="col-lg-12">
          <p>{t("trade.nav-tabs.content.1.38")}</p>
          <p>{t("trade.nav-tabs.content.1.39")}</p>
        </div>
      </div>
    </div>
  );

  const data: NavTab[] = [
    {
      nav: t("trade.nav-tabs.nav.1"),
      content: content1,
    },
    {
      nav: t("trade.nav-tabs.nav.2"),
      content: content2,
    },
  ];

  return <NavTabs data={data} />;
};

export default TradeTabs;
