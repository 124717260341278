import { ListPresentProps } from "./ListPresentType";

const ListPresent = ({ items, col = "col" }: ListPresentProps) => {
  return (
    <div className="list-present">
      <div className="row gy-3">
        {items.map((item, i) => (
          <div className={col} key={i}>
            <div className="content-wrapper d-flex d-sm-block d-md-block h-100">
              {item.icon && (
                <div className="icon mb--30 mb_sm--0 mb_md--0">{item.icon}</div>
              )}
              <div className="content">
                {item.title && (
                  <h3 className="title mb--25 mb_sm--5 mb_md--10">
                    {item.title}
                  </h3>
                )}
                {item.description && (
                  <p className="lineheight--1-8">{item.description}</p>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ListPresent;
