import { useState, useEffect } from "react";

const getWindowSize = () => {
  const { innerWidth, innerHeight } = window;
  return { innerWidth, innerHeight };
};

// Hook
const useWindowSize = () => {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    // only execute all the code below in client side
    if (typeof window !== "undefined") {
      // Handler to call on window resize
      const handleWindowResize = () => {
        // Set window width/height to state
        setWindowSize(getWindowSize());
      };
      // Add event listener
      window.addEventListener("resize", handleWindowResize);
      // Remove event listener on cleanup
      return () => {
        window.removeEventListener("resize", handleWindowResize);
      };
    }
  }, []); // Empty array ensures that effect is only run on mount

  return windowSize;
};

export default useWindowSize;
