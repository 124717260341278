import { useTranslation } from "react-i18next";
import PageHelment from "../components/common/Helment";
import CallAction from "../components/partial/CallAction";
import Breadcrumb from "../components/header/Breadcrumb";
import IntroDark from "../blocks/intro/IntroDark";
import banner17 from "../resources/banner/banner17.jpg";
import MarginInOutTabs from "../blocks/account/MarginInOutTabs";

const AccountMarginInOut = () => {
  const { t } = useTranslation();
  return (
    <>
      <PageHelment
        title={`${t("common.meta.prefix")} | ${t(
          "margin_inout.meta.title"
        )} | ${t("common.meta.suffix")}`}
        description={t("margin_inout.meta.description")}
      />
      <CallAction
        bgImage={<img src={banner17} alt={t("margin_inout.meta.title")} />}
        className="ptb--100"
        textAlign="left"
        h1={t("margin_inout.banner.title")}
        description={t("margin_inout.banner.description")}
      />
      <Breadcrumb
        parentPage={t("margin_inout.breadcrumb.level.1")}
        currentPage={t("margin_inout.breadcrumb.level.2")}
      />

      <IntroDark
        title={t("margin_inout.intro.header")}
        description={t("margin_inout.intro.desc")}
      ></IntroDark>
      <MarginInOutTabs />
    </>
  );
};

export default AccountMarginInOut;
