import { useTranslation } from "react-i18next";
import PageHelment from "../components/common/Helment";
import CallAction from "../components/partial/CallAction";
import Breadcrumb from "../components/header/Breadcrumb";
import IntroDark from "../blocks/intro/IntroDark";
import banner15 from "../resources/banner/banner15.jpg";
import ActionButton, {
  ActionButtonA,
} from "../components/partial/ActionButton";
import OpenAccountContent from "../blocks/account/OpenAccountContent";

const OpenAcc = () => {
  const { t } = useTranslation();
  return (
    <>
      <PageHelment
        title={`${t("common.meta.prefix")} | ${t("openacc.meta.title")} | ${t(
          "common.meta.suffix"
        )}`}
        description={t("openacc.meta.description")}
      />
      <CallAction
        bgImage={<img src={banner15} alt={t("openacc.meta.title")} />}
        className="ptb--100"
        textAlign="left"
        tagline={t("openacc.banner.tagline")}
        h1={t("openacc.banner.title")}
        description={t("openacc.banner.description")}
        button={
          <ActionButtonA target="_blank" href={`/live-acc-reg/reg.htm`}>
            {t("openacc.banner.button.text")}
          </ActionButtonA>
        }
      />
      <Breadcrumb
        parentPage={t("openacc.breadcrumb.level.1")}
        currentPage={t("openacc.breadcrumb.level.2")}
      />

      <IntroDark
        title={t("openacc.intro.header")}
        description={t("openacc.intro.desc")}
      >
        <div className="mt--70 mt_sm--50 text-center text-md-start">
          <ActionButtonA
            target="_blank"
            href={`/assets/download/agreement.pdf`}
          >
            {t("openacc.intro.button.text")}
          </ActionButtonA>
        </div>
      </IntroDark>
      <OpenAccountContent />
    </>
  );
};

export default OpenAcc;
