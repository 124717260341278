import { useTranslation } from "react-i18next";
import bg14 from "../resources/background/bg14.png";
import PageHelment from "../components/common/Helment";
import Breadcrumb from "../components/header/Breadcrumb";
import CallAction from "../components/partial/CallAction";
import AnnouncementList from "../blocks/announcement/AnnouncementList";

const Announcements = () => {
  const { t } = useTranslation();
  return (
    <>
      <PageHelment
        title={`${t("common.meta.prefix")} | ${t(
          "announcement.meta.title"
        )} | ${t("common.meta.suffix")}`}
        description={t("announcement.meta.description")}
      />
      <CallAction
        bgImage={<img src={bg14} alt={t("announcement.meta.title")} />}
        className="ptb--100"
        textAlign="left"
        tagline={t("announcement.banner.tagline")}
        h1={t("announcement.banner.title")}
        description={t("announcement.banner.description")}
      />
      <Breadcrumb parentPage={t("announcement.breadcrumb.level.1")} />
      <AnnouncementList />
    </>
  );
};

export default Announcements;
