import { useTranslation } from "react-i18next";
import { ContentImageRowFullWidth } from "../../components/partial/ContentImageRow";
import img from "../../resources/platforms/trading_central_screen_01.png";
import SectionTitle from "../../components/partial/SectionTitle";
import ActionButton, {
  ActionButtonA,
} from "../../components/partial/ActionButton";
import TradingCentralModal from "./TradingCentralModal";
import { useState } from "react";

const TradingCentralSection1 = () => {
  const { t, i18n } = useTranslation();
  const [showModal, setShowModal] = useState(false);

  return (
    <div className="trading-central-section-1">
      <ContentImageRowFullWidth
        imagePosition={"right"}
        imageSrc={img}
        isHalfWidth={true}
      >
        <div className="mb--100 mb_sm--60">
          <SectionTitle title={t("trading_central.content.1.header")} />
        </div>
        <div className="d-flex justify-content-center justify-content-md-start button-container mb--90 mb_sm--50">
          <div className="wrapper text-center">
            <ActionButtonA
              className="light"
              target="_blank"
              href={`https://global.tradingcentral.com/mt/TRADING_CENTRAL_MetaTrader_Analytics_CN_v_4.0.2302.32.exe`}
            >
              {t("trading_central.content.1.button.text.1")}
            </ActionButtonA>
          </div>
          <div className="wrapper text-center">
            <ActionButton
              className="light"
              to={""}
              onClick={() => setShowModal(true)}
            >
              {t("trading_central.content.1.button.text.2")}
            </ActionButton>
          </div>
        </div>

        <p className="">
          {t("trading_central.content.1.text.1")}
          <br />
          {t("trading_central.content.1.text.2")}
          <br />
          {t("trading_central.content.1.text.3")}
          <br />
          {t("trading_central.content.1.text.4")}
          <br />
        </p>
      </ContentImageRowFullWidth>
      <TradingCentralModal
        isShow={showModal}
        onClose={(show) => setShowModal(show)}
      />
    </div>
  );
};

export default TradingCentralSection1;
