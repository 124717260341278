import { useTranslation } from "react-i18next";
import PageHelment from "../components/common/Helment";
import Breadcrumb from "../components/header/Breadcrumb";
import CallAction from "../components/partial/CallAction";
import banner22 from "../resources/banner/banner22.jpg";
import mt4Logo from "../resources/platforms/mt4.png";
import IntroDark from "../blocks/intro/IntroDark";
import ActionButton, {
  ActionButtonA,
} from "../components/partial/ActionButton";
import MT4Content from "../blocks/platform/MT4Content";
import { ReactComponent as LogoIOS } from "../../src/resources/svg/download_ios.svg";
import { ReactComponent as LogoAndroid } from "../../src/resources/svg/download_android.svg";
import { ReactComponent as LogoWin } from "../../src/resources/svg/download_win.svg";

const DownloadIcons = () => {
  return (
    <div className="d-flex justify-content-center justify-content-md-start flex-wrap mb--20 mb_sm--50">
      <div className="icons mr_sm--0 mr--30">
        <a
          target="_blank"
          href="https://apps.apple.com/hk/app/metatrader-4/id496212596"
        >
          <LogoIOS />
        </a>
      </div>
      <div className="icons mr_sm--0 mr--30">
        <a
          target="_blank"
          href="https://www.hantecbullion.com/downloads/mt4/android/MetaTrader_4.apk"
        >
          <LogoAndroid />
        </a>
      </div>
      <div className="icons mr_sm--0 mr--30">
        <a
          target="_blank"
          href="https://download.mql5.com/cdn/web/8056/mt4/hantecbullion4setup.exe"
        >
          <LogoWin />
        </a>
      </div>
    </div>
  );
};

const PlatformMT4 = () => {
  const { t } = useTranslation();
  return (
    <>
      <PageHelment
        title={`${t("common.meta.prefix")} | ${t("mt4.meta.title")} | ${t(
          "common.meta.suffix"
        )}`}
        description={t("mt4.meta.description")}
      />
      <CallAction
        bgImage={<img src={banner22} alt={t("mt4.meta.title")} />}
        className="ptb--100"
        textAlign="left"
        tagline={t("mt4.banner.tagline")}
        h1={t("mt4.banner.title")}
        description={t("mt4.banner.description")}
      />
      <Breadcrumb
        parentPage={t("mt4.breadcrumb.level.1")}
        currentPage={t("mt4.breadcrumb.level.2")}
      />
      <IntroDark
        title={t("mt4.intro.header")}
        description={t("mt4.intro.description")}
        image={
          <img
            src={mt4Logo}
            alt={t("mt4.meta.title")}
            style={{
              maxHeight: "auto",
              padding: "0 8px",
              backgroundColor: "#fff",
              borderRadius: "0 8px",
            }}
          />
        }
        hasDownlod={true}
      >
        <div className="mt--30 mt_sm--50 text-center text-md-start">
          <DownloadIcons />
          <ActionButtonA
            target="_blank"
            href={`/assets/download/mt4handbook-HBL.pdf`}
          >
            {t("mt4.intro.button.text")}
          </ActionButtonA>
        </div>
      </IntroDark>
      <MT4Content />
    </>
  );
};

export default PlatformMT4;
