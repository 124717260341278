import { useTranslation } from "react-i18next";
import { ContentImageRowFullWidth } from "../../components/partial/ContentImageRow";
import SectionTitle from "../../components/partial/SectionTitle";
import img01 from "../../resources/ibsw/ib_01.jpg";
import { ReactComponent as Icon01 } from "../../resources/svg/icon_ib_support_01.svg";
import { ReactComponent as Icon02 } from "../../resources/svg/icon_ib_support_02.svg";
import { ReactComponent as Icon03 } from "../../resources/svg/icon_ib_support_03.svg";
import { ReactComponent as Icon04 } from "../../resources/svg/icon_ib_support_04.svg";

import icon01 from "../../resources/ibsw/Hantec_graphic_01-26.png";
import icon02 from "../../resources/ibsw/Hantec_graphic_01-27.png";
import icon03 from "../../resources/ibsw/Hantec_graphic_01-28.png";
import icon04 from "../../resources/ibsw/Hantec_graphic_01-29.png";

const IbContent = () => {
  const { t, i18n } = useTranslation();

  const list = Array(4)
    .fill("")
    .map((e, i) => {
      let icon;
      switch (i + 1) {
        case 1:
          icon = (
            <img src={icon01} alt={t(`IB.content.support.title.${i + 1}`)} />
          );
          break;
        case 2:
          icon = (
            <img src={icon02} alt={t(`IB.content.support.title.${i + 1}`)} />
          );
          break;
        case 3:
          icon = (
            <img src={icon03} alt={t(`IB.content.support.title.${i + 1}`)} />
          );
          break;
        case 4:
          icon = (
            <img src={icon04} alt={t(`IB.content.support.title.${i + 1}`)} />
          );
          break;
        default:
          icon = (
            <img src={icon01} alt={t(`IB.content.support.title.${i + 1}`)} />
          );
          break;
      }

      return {
        icon: icon,
        title: t(`IB.content.support.title.${i + 1}`),
        description: t(`IB.content.support.description.${i + 1}`),
      };
    });

  return (
    <div className="ib-content bg-style--19 bg_image">
      <ContentImageRowFullWidth imagePosition="right" imageSrc={img01}>
        <div className="content-container pt--75 pt_md--0 pt_sm--0">
          <SectionTitle title={t("IB.content.support.header")} />

          <div className="row mt--100 mt_md--50 mt_sm--50 mb--25 mr--100 mr_lg--70 mr_md--0 mr_sm--0">
            {list.map((item, i) => (
              <div className={`col-12`} key={i}>
                <div className="content-wrapper h-100">
                  {item.icon && (
                    <div className="icon mb--30 mb_sm--0 mb_md--0">
                      {item.icon}
                    </div>
                  )}
                  <div className="content">
                    {item.title && (
                      <h4 className="title mb--15 mb_sm--5 mb_md--10">
                        {item.title}
                      </h4>
                    )}
                    {item.description && (
                      <p className="lineheight--1-8">{item.description}</p>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </ContentImageRowFullWidth>
    </div>
  );
};

export default IbContent;
