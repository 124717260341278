import { useTranslation } from "react-i18next";
import img03 from "../../resources/about-content/03.jpg";
import img04 from "../../resources/about-content/04.jpg";
import img05 from "../../resources/about-content/05.jpg";
import { Content } from "../../components/partial/TwoColumnsContent.type";
import TwoColumnsContent from "../../components/partial/TwoColumnsContent";
import SectionTitle from "../../components/partial/SectionTitle";

const AboutHblProtection = () => {
  const { t } = useTranslation();

  const data: Content[] = [
    {
      title: t("aboutHblAdv.content.section3.title.1"),
      description: t("aboutHblAdv.content.section3.description.1"),
      image: (
        <img
          src={img03}
          className="img img-br"
          alt={t("aboutHblAdv.content.section3.title.1")}
        />
      ),
    },
    {
      title: t("aboutHblAdv.content.section3.title.2"),
      description: t("aboutHblAdv.content.section3.description.2"),
      image: (
        <img
          src={img04}
          className="img img-br"
          alt={t("aboutHblAdv.content.section3.title.2")}
        />
      ),
    },
    {
      title: t("aboutHblAdv.content.section3.title.3"),
      description: t("aboutHblAdv.content.section3.description.3"),
      image: (
        <img
          src={img05}
          className="img img-br"
          alt={t("aboutHblAdv.content.section3.title.3")}
        />
      ),
    },
  ];

  return (
    <div className="about-hbl-protection pt--150 pt_sm--60 pt_md--60">
      <div className="container">
        <div className="row justify-content-left">
          <div className="col-lg-12">
            <div className="text-left mb_sm--60 mb_md--60">
              <SectionTitle title={t("aboutHblAdv.content.section3.header")} />
            </div>
          </div>
        </div>
      </div>
      <TwoColumnsContent className="about-hbl-protection" contents={data} />
    </div>
  );
};

export default AboutHblProtection;
