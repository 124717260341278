import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
  fallbackLng: "sc",
  resources: {
    sc: {
      translations: require("./i18n/sc.json"),
    },
    tc: {
      translations: require("./i18n/tc.json"),
    },
  },
  ns: ["translations"],
  defaultNS: "translations",
});

i18n.languages = ["tc", "sc"];

export default i18n;

// https://www.freecodecamp.org/news/how-to-add-localization-to-your-react-app/
