import { useTranslation } from "react-i18next";
import { Icon } from "@ailibs/feather-react-ts";
import { useState } from "react";
import classnames from "classnames";
import useOutsideClick from "../../hooks/useOutsideClick";
import { ReactComponent as IconLanguage } from "../../resources/svg/icon_global.svg";
import { ReactComponent as IconLanguage17 } from "../../resources/svg/icon_global_17.svg";
import { LANGUAGES } from "../../constants";
import { useLocation, useNavigate } from "react-router-dom";
import useWindowSize from "../../hooks/useWindowSize";

const LanguageSwitcher = () => {
  const { t, i18n } = useTranslation();

  const location = useLocation();
  let navigate = useNavigate();

  const [openMenu, setOpenMenu] = useState(false);

  const ref = useOutsideClick(() => setOpenMenu(false));

  // prevent bubbling
  const handleWrapperClick = (event: React.MouseEvent) => {
    event.stopPropagation();
  };

  const currentLanguage = i18n.language;
  const languageSwitch = (locale: string) => {
    setOpenMenu(false);
    // split the pathname
    let paths = location.pathname.split("/");
    // update the locale in url
    paths[1] = locale;
    // do navigation
    navigate(paths.join("/"));

    // change language
    i18n.changeLanguage(locale);
    // do refresh
    // navigate(0);
  };

  const size = useWindowSize();

  return (
    <div className="langauge-switcher-container">
      {size.innerWidth < 1800 && <IconLanguage />}
      {size.innerWidth > 1799 && <IconLanguage17 />}
      <div ref={ref} className="switch-lang" onClick={handleWrapperClick}>
        <div className="current-lang" onClick={() => setOpenMenu(true)}>
          <span className="lang-text">
            {t(
              LANGUAGES.find((lang, i) => lang.locale === currentLanguage)
                ?.label!
            )}
          </span>
          <Icon
            name={openMenu ? "chevron-up" : "chevron-down"}
            color="#fff"
            size={16}
          />
        </div>
        <div className={classnames("lang-dropdown", { open: openMenu })}>
          {LANGUAGES.filter((l) => l.locale === l.locale).map((l, i) => {
            const cn = classnames("selecting-lang text-center", {
              active: l.locale === currentLanguage,
            });
            return (
              <div
                className={cn}
                key={i}
                onClick={() => languageSwitch(l.locale)}
              >
                <span className="lang-text">{t(l.label)}</span>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default LanguageSwitcher;
