import { useTranslation } from "react-i18next";
import ActionButton, {
  ActionButtonA,
} from "../../components/partial/ActionButton";
import SectionTitle from "../../components/partial/SectionTitle";
import { useEffect, useState } from "react";
import classnames from "classnames";
import img01 from "../../resources/ibsw/01.jpg";
import img02 from "../../resources/ibsw/02.jpg";
import img03 from "../../resources/ibsw/03.jpg";
import img04 from "../../resources/ibsw/04.jpg";
import img05 from "../../resources/ibsw/05.jpg";
import img06 from "../../resources/ibsw/06.jpg";
import img07 from "../../resources/ibsw/07.jpg";
import img08 from "../../resources/ibsw/08.jpg";
import img09 from "../../resources/ibsw/09.jpg";
import img10 from "../../resources/ibsw/10.jpg";
import img11 from "../../resources/ibsw/11.jpg";
import img12 from "../../resources/ibsw/12.jpg";
import img13 from "../../resources/ibsw/13.jpg";
import { Link } from "react-router-dom";
import { isExternalUrl } from "../../helpers";

const IbswBlock = () => {
  const { t, i18n } = useTranslation();

  const link1 =
    i18n.language === "tc"
      ? "/ae-web/login.htm?siteLanguage=zh_HK"
      : "/ae-web/login.htm?siteLanguage=zh_CN";
  const link2 = "/assets/download/ib_statement.pdf";
  const link3 = "/live-acc-reg/reg.htm";
  const link4 = "/assets/download/client_opening.pdf";
  const link5 =
    i18n.language === "tc"
      ? "/ae-web/login.htm?siteLanguage=zh_HK"
      : "/ae-web/login.htm?siteLanguage=zh_CN";
  const link6 = "/assets/download/ib_bonus.pdf";
  const link7 = `/${i18n.language}/margin_inout`;
  const link8 = `/${i18n.language}/margin_inout`;
  const link9 =
    i18n.language === "tc"
      ? "/cs-web-hbl/login.htm?siteLanguage=zh_HK"
      : "/cs-web-hbl/login.htm?siteLanguage=zh_CN";
  const link10 = `/${i18n.language}/margin_inout`;
  const link11 = `/${i18n.language}/demoacc`;
  const link12 = `/${i18n.language}/demoacc`;
  const link13 = `/assets/download/ib_backend.pdf`;
  const link14 = `http://update.bullionhantec.com/Software/HBLMT4/TradingStation/HBLMT4_Installer.html`;
  const link15 = `/dl/Hantec-IB-Back-Office-4.8.330-PRD.apk`;
  const link16 = `/ib-acc-open/opening-profile`;
  const link17 = `/Software/HBL/TradingStation/Hantec-eGold-4.14.36.12.apk`;
  const link18 = `/dl/HTS_HBL_Setup_Full_4_14_15.exe`;
  const link19 = `/dl/MetaTrader4-1391.apk`;
  const link20 = `/dl/hantecbullion4setup.exe`;
  const link21 = `/assets/download/client_aof.pdf`;
  const link22 = `/assets/download/change_info.pdf`;
  const link23 = `/assets/download/payment_instru.pdf`;
  const link24 = `/assets/download/pw_reset.pdf`;
  const link25 = `/assets/download/data_statement.pdf`;
  const link26 = `/assets/download/fatca_decla.pdf`;
  const link27 = `/assets/download/Group_Brochure_SC.pdf`;
  const link28 = ``;

  const SingleButtonsDropDown = () => {
    const [menuOpen, setMenuOpen] = useState(false);
    return (
      <div className={classnames("single-column")}>
        <ActionButton
          to=""
          className={classnames("light", { "menu-open": menuOpen })}
          style={{ width: "100%" }}
          onClick={() => setMenuOpen(true)}
        >
          {t("common.button.download-online")}
        </ActionButton>

        <ActionButtonA
          href={link21}
          className={classnames("secondary", { hide: !menuOpen })}
        >
          {t("IBSW.nav-tabs.content.28")}
        </ActionButtonA>
        <ActionButtonA
          href={link22}
          className={classnames("secondary", { hide: !menuOpen })}
        >
          {t("IBSW.nav-tabs.content.29")}
        </ActionButtonA>
        <ActionButtonA
          href={link23}
          className={classnames("secondary", { hide: !menuOpen })}
        >
          {t("IBSW.nav-tabs.content.30")}
        </ActionButtonA>
        <ActionButtonA
          href={link24}
          className={classnames("secondary", { hide: !menuOpen })}
        >
          {t("IBSW.nav-tabs.content.31")}
        </ActionButtonA>
        <ActionButtonA
          href={link25}
          className={classnames("secondary", { hide: !menuOpen })}
        >
          {t("IBSW.nav-tabs.content.33")}
        </ActionButtonA>
        <ActionButtonA
          href={link26}
          className={classnames("secondary", { hide: !menuOpen })}
        >
          {t("IBSW.nav-tabs.content.34")}
        </ActionButtonA>
      </div>
    );
  };

  const categories = [
    {
      title: t("IBSW.nav-tabs.category.1"),
      name: "cat1",
    },
    {
      title: t("IBSW.nav-tabs.category.2"),
      name: "cat2",
    },
    {
      title: t("IBSW.nav-tabs.category.3"),
      name: "cat3",
    },
    {
      title: t("IBSW.nav-tabs.category.4"),
      name: "cat4",
    },
    {
      title: t("IBSW.nav-tabs.category.5"),
      name: "cat5",
    },
  ];

  type ContentType = {
    image?: string;
    title?: string;
    link?: string;
    content: React.ReactNode;
    category: string;
  };

  const defaultData = [
    {
      image: img01,
      title: t("IBSW.nav-tabs.content.1"),
      link: link1,
      content: (
        <ActionButtonA className="light" href={link2}>
          {t("IBSW.nav-tabs.content.2")}
        </ActionButtonA>
      ),
      category: "cat1",
    },
    {
      image: img02,
      title: t("IBSW.nav-tabs.content.3"),
      link: link3,
      content: (
        <ActionButtonA className="light" href={link4}>
          {t("IBSW.nav-tabs.content.4")}
        </ActionButtonA>
      ),
      category: "cat1",
    },
    {
      image: img03,
      title: t("IBSW.nav-tabs.content.5"),
      link: link5,
      content: (
        <ActionButtonA className="light" href={link6}>
          {t("IBSW.nav-tabs.content.6")}
        </ActionButtonA>
      ),
      category: "cat1",
    },
    {
      image: img04,
      title: t("IBSW.nav-tabs.content.7"),
      link: link7,
      content: (
        <ActionButtonA className="light" href={link8}>
          {t("IBSW.nav-tabs.content.8")}
        </ActionButtonA>
      ),
      category: "cat1",
    },
    {
      image: img05,
      title: t("IBSW.nav-tabs.content.9"),
      link: link9,
      content: (
        <ActionButtonA className="light" href={link10}>
          {t("IBSW.nav-tabs.content.10")}
        </ActionButtonA>
      ),
      category: "cat1",
    },
    {
      image: img06,
      title: t("IBSW.nav-tabs.content.11"),
      link: link11,
      content: (
        <ActionButtonA className="light" href={link12}>
          {t("IBSW.nav-tabs.content.12")}
        </ActionButtonA>
      ),
      category: "cat1",
    },
    {
      image: img07,
      title: t("IBSW.nav-tabs.content.13"),
      content: (
        <>
          <ActionButtonA className="light" href={link13}>
            {t("IBSW.nav-tabs.content.14")}
          </ActionButtonA>
          <ActionButtonA className="light" href={link14}>
            {t("IBSW.nav-tabs.content.15")}
          </ActionButtonA>
        </>
      ),
      category: "cat2",
    },
    {
      image: img08,
      title: t("IBSW.nav-tabs.content.16"),
      content: (
        <>
          <ActionButtonA className="light" href={link15}>
            {t("IBSW.nav-tabs.content.17")}
          </ActionButtonA>
        </>
      ),
      category: "cat2",
    },
    {
      image: img09,
      title: t("IBSW.nav-tabs.content.19"),
      content: (
        <>
          <ActionButtonA className="light" href={link16}>
            {t("IBSW.nav-tabs.content.20")}
          </ActionButtonA>
        </>
      ),
      category: "cat3",
    },
    {
      image: img10,
      title: t("IBSW.nav-tabs.content.21"),
      content: (
        <>
          <ActionButtonA className="light" href={link17}>
            {t("IBSW.nav-tabs.content.22")}
          </ActionButtonA>
          <ActionButtonA className="light" href={link18}>
            {t("IBSW.nav-tabs.content.23")}
          </ActionButtonA>
        </>
      ),
      category: "cat4",
    },
    {
      image: img11,
      title: t("IBSW.nav-tabs.content.24"),
      content: (
        <>
          <ActionButtonA className="light" href={link19}>
            {t("IBSW.nav-tabs.content.25")}
          </ActionButtonA>
          <ActionButtonA className="light" href={link20}>
            {t("IBSW.nav-tabs.content.26")}
          </ActionButtonA>
        </>
      ),
      category: "cat4",
    },
    {
      image: img12,
      title: t("IBSW.nav-tabs.content.27"),
      content: <SingleButtonsDropDown />,
      category: "cat5",
    },
    {
      image: img13,
      title: t("IBSW.nav-tabs.content.35"),
      content: (
        <div className="single-column">
          <ActionButtonA href={link27} className={classnames("light")}>
            {t("IBSW.nav-tabs.content.36")}
          </ActionButtonA>
        </div>
      ),
      category: "cat5",
    },
  ];

  // the state
  const [activeCategory, setActiveCategory] = useState(categories.at(0)?.name);
  const [filteredData, setFilteredData] = useState<Array<ContentType>>([]);
  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    const d = defaultData.filter(
      (item: ContentType) =>
        item.category === activeCategory || activeCategory === "all"
    );
    setFilteredData(d);
  }, [activeCategory, i18n.language]);

  return (
    <div className="ibsw-block-container">
      <div className="container-lg gx-0">
        {/* filter row */}
        <div className="row mt--45 mt_sm--0 mt_md--0">
          <div className="col col-lg-auto">
            <ul className="liststyle d-flex tag-navs">
              {categories.map((c, i) => {
                return (
                  <li
                    className={classnames({
                      active: c.name === activeCategory,
                      open: menuOpen,
                    })}
                    key={i}
                  >
                    <SectionTitle
                      onClick={() => {
                        setActiveCategory(c.name);
                        setMenuOpen(!menuOpen);
                      }}
                      title={c.title}
                    />
                  </li>
                );
              })}
              <li
                className={classnames("d-lg-none", {
                  active: "all" === activeCategory,
                  open: menuOpen,
                })}
              >
                <SectionTitle
                  onClick={() => {
                    setActiveCategory("all");
                    setMenuOpen(!menuOpen);
                  }}
                  title={t("common.button.show-all")}
                />
              </li>
            </ul>
          </div>
          <div className="col d-none d-lg-block">
            <div className="select-all-wrapper">
              <label htmlFor="switch">{t("common.button.show-all")}</label>
              <input
                type="checkbox"
                id="switch"
                checked={activeCategory === "all"}
                onChange={() => {
                  if (activeCategory === "all") {
                    setActiveCategory(categories.at(0)?.name);
                  } else {
                    setActiveCategory("all");
                  }
                }}
              />
              <label htmlFor="switch" className="switch">
                Toggle
              </label>
            </div>
          </div>
        </div>

        {/* content */}
        <div className="row mt_lg--50 mt_md--50 mt_sm--0 mb_sm--0 mt--150 mb--50 gx-5 ">
          {filteredData.map((d, i) => {
            const colClassNames = classnames("col-12", {
              "col-sm-12": filteredData.length === 1,
              "col-sm-6": filteredData.length === 2,
              "col-sm-6 col-md-6 col-lg-4": filteredData.length >= 3,
            });
            const imgClassNames = classnames(
              "",
              "item-image-wrapper wrapper",
              `item-image-wrapper-${filteredData.length}`
            );

            return (
              <div className={colClassNames} key={i}>
                <div className="ibsw-item pb--100 ptb_lg--50 pt_md--0 pt_sm--0 pb_sm--60">
                  {/* image */}
                  <div className="wrapper mb--30">
                    <div
                      className={imgClassNames}
                      style={{ backgroundImage: `url(${d.image})` }}
                    ></div>
                    <div className="link-title-wrapper">
                      <a
                        href={d.link || ""}
                        target={isExternalUrl(d.link) ? "_blank" : "_self"}
                      >
                        {d.title && <h3 className="">{d.title}</h3>}
                      </a>
                    </div>
                  </div>

                  {/* title */}
                  {d.title && (
                    <h2 className="item-title text-center mb--40">{d.title}</h2>
                  )}
                  {/* content */}
                  {d.content && (
                    <div className="d-flex flex-wrap justify-content-center align-items-center mb--10 plr--10 column-gap-3 row-gap-3">
                      {d.content}
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
export default IbswBlock;
