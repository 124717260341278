import { useTranslation } from "react-i18next";
import classnames from "classnames";
import { LANGUAGES } from "../../constants";
import { Link, useLocation, useNavigate } from "react-router-dom";

interface LanguageSwitcherSimpleProps {
  className?: string;
  onLanguageSwitch?: () => void;
}

const LanguageSwitcherSimple = ({
  className,
  onLanguageSwitch = () => {},
}: LanguageSwitcherSimpleProps) => {
  const { t, i18n } = useTranslation();

  const location = useLocation();
  let navigate = useNavigate();

  const currentLanguage = i18n.language;
  const languageSwitch = (locale: string) => {
    if (locale === currentLanguage) return;
    // split the pathname
    let paths = location.pathname.split("/");
    // update the locale in url
    paths[1] = locale;
    // do navigation
    navigate(paths.join("/"));

    // change language
    i18n.changeLanguage(locale);
    // do refresh
    // navigate(0);

    // callback
    onLanguageSwitch();
  };

  const classNames = classnames("langauge-link-container", className);
  return (
    <div className={classNames}>
      {LANGUAGES.map((l, i) => {
        const classNames = classnames("langauge-link-text", {
          current: currentLanguage === l.locale,
        });
        return (
          <a
            className={classNames}
            key={i}
            onClick={() => languageSwitch(l.locale)}
          >
            {t(l.short)}
          </a>
        );
      })}
    </div>
  );
};

export default LanguageSwitcherSimple;
