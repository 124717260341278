import { useTranslation } from "react-i18next";
import { ContentImageRowFullWidth } from "../../components/partial/ContentImageRow";
import SectionTitle from "../../components/partial/SectionTitle";
import img01 from "../../resources/account/account01.jpg";
import ActionButton, {
  ActionButtonA,
} from "../../components/partial/ActionButton";

const OpenAccountContent = () => {
  const { t, i18n } = useTranslation();
  return (
    <div className="open-account-content bg-style--3_ bg_image">
      <ContentImageRowFullWidth imagePosition="right" imageSrc={img01}>
        <div className="content-container">
          <SectionTitle title={t("opanacc.content.header")} />
          <div>
            <p>{t("opanacc.content.p1")}</p>
            <p>{t("opanacc.content.p2")}</p>
            <p>{t("opanacc.content.p3")}</p>
            <p>{t("opanacc.content.p4")}</p>
          </div>
          <div>
            <ActionButtonA
              className="light"
              id="acc_open_2"
              target="_blank"
              href={`/live-acc-reg/reg.htm`}
            >
              {t("opanacc.content.button.text")}
            </ActionButtonA>
          </div>
        </div>
      </ContentImageRowFullWidth>
    </div>
  );
};

export default OpenAccountContent;
