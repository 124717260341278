import { useTranslation } from "react-i18next";
import egoldScreens from "../../resources/platforms/egold_screen.png";

import { Content } from "../../components/partial/TwoColumnsContent.type";
import SectionTitle from "../../components/partial/SectionTitle";

const EGoldContent = () => {
  const { t } = useTranslation();

  return (
    <div className="egold-content-wrapper bg-style--3_ bg_image">
      <div className="pt--150 pb--85 pt_md--60 pt_md--75 pt_sm--60 pt_sm--75">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-6 d-md-none text-center">
              <img
                className="mb--60"
                src={egoldScreens}
                alt={t("egold.content.header")}
              />
            </div>
            <div className="col-12 col-md-7">
              <SectionTitle title={t("egold.content.header")} />
              <div className="mt--100 mt_md--60 mt_sm--60">
                <h3 className="theme-color mt--60 mt_md--50 mt_sm--50">
                  {t("egold.content.title.1")}
                </h3>
                <p>{t("egold.content.description.1")}</p>
                <h3 className="theme-color mt--60 mt_md--50 mt_sm--50">
                  {t("egold.content.title.2")}
                </h3>
                <p>{t("egold.content.description.2")}</p>
                <h3 className="theme-color mt--60 mt_md--50 mt_sm--50">
                  {t("egold.content.title.3")}
                </h3>
                <p>{t("egold.content.description.3")}</p>
              </div>
            </div>
            <div className="col-12 col-md-5">
              <img
                className="img-screen-50"
                src={egoldScreens}
                alt={t("egold.content.header")}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EGoldContent;
