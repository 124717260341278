import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import classnames from "classnames";
import { Link } from "react-router-dom";
import { ReactComponent as Logo } from "../../resources/svg/logo_white.svg";
import { ReactComponent as IconBusinessUser } from "../../resources/svg/icon_user_business.svg";
import { ReactComponent as IconBusinessUser17 } from "../../resources/svg/icon_user_business_17.svg";
import { ReactComponent as IconCustomerService } from "../../resources/svg/icon_customer_service.svg";
import { ReactComponent as IconCustomerService17 } from "../../resources/svg/icon_customer_service_17.svg";
import { ReactComponent as IconNewAccount } from "../../resources/svg/icon_new_account.svg";
import { ReactComponent as IconNewAccountRed } from "../../resources/svg/icon_new_account_red.svg";
import { ReactComponent as IconHumbergerMenu } from "../../resources/svg/icon_humberger_menu.svg";
import { ReactComponent as IconMenuClose } from "../../resources/svg/icon_menu_close.svg";
import LanguageSwitcher from "./LanguageSwitcher";
import Menu from "./Menu";
import LanguageSwitcherSimple from "./LanguageSwitcherSimple";
import ActionButton, { ActionButtonA } from "../partial/ActionButton";
import useWindowSize from "../../hooks/useWindowSize";
import { LANGUAGES } from "../../constants";
import HeaderNavDropDown from "./HeaderNavDropDown";

const Header = () => {
  const { t, i18n } = useTranslation();
  const [wrapperOpen, setWrapperOpen] = useState(false);
  const [mouseOverCTA, setMouseOverCTA] = useState(false);

  const menuTrigger = () => {
    setWrapperOpen(true);
  };

  const CLoseMenuTrigger = () => {
    setWrapperOpen(false);
  };

  useEffect(() => {
    const html = document.querySelector("html");
    if (html) {
      html.style.overflow = wrapperOpen ? "hidden" : "auto";
    }
  }, [wrapperOpen]); // condition to watch to perform side effect

  // get the window size
  const size = useWindowSize();

  const link1 =
    i18n.language === "tc"
      ? "/cs-web-hbl/login.htm?siteLanguage=zh_HK"
      : "/cs-web-hbl/login.htm?siteLanguage=zh_CN";
  const link2 =
    "https://wpa.b.qq.com/cgi/wpa.php?ln=1&key=XzkzODA0NTYxOF80OTM2NDFfNDAwMTIwMDEwOF8yXw";

  const link3 = "/live-acc-reg/reg.htm";

  return (
    <header
      className={classnames("header-area sticky formobile-menu color-white", {
        "menu-open": wrapperOpen,
      })}
    >
      <div
        className={`header-wrapper ${wrapperOpen ? "menu-open" : ""}`}
        id="header-wrapper"
      >
        <div className="header-left">
          <div className="logo">
            <Link
              to={`/${i18n.language}`}
              onClick={() => setWrapperOpen(false)}
            >
              <Logo />
            </Link>
          </div>
          <div className="separater"></div>
          <div className="license">
            <div>{t("header.license.line1")}</div>
            <div>{t("header.license.line2")}</div>
          </div>
        </div>
        <div className={classnames("header-right")}>
          <div className="header-nav-container">
            <div className="top-links-container">
              <ul className="top-links liststyle">
                <li>
                  <a href={link1} target="_blank" id="client_center_1">
                    {size.innerWidth < 1800 && <IconBusinessUser />}
                    {size.innerWidth > 1799 && <IconBusinessUser17 />}
                    <span>{t("header.customer-center")}</span>
                  </a>
                </li>
                {/* <li>
                  <a href={link2} target="_blank" id="online_cs_1">
                    {size.innerWidth < 1800 && <IconCustomerService />}
                    {size.innerWidth > 1799 && <IconCustomerService17 />}
                    <span>{t("header.online-cs")}</span>
                  </a>
                </li> */}
                <li>
                  <HeaderNavDropDown />
                </li>
                <li>
                  <LanguageSwitcher />
                </li>
              </ul>
              <div
                className={classnames(
                  "header-btn d-none d-lg-flex d-xl-block d-xxl-none",
                  {
                    open: wrapperOpen,
                  }
                )}
              >
                {/* mobile */}
                <ActionButtonA
                  className="rn-btn"
                  href={link3}
                  id="acc_open_1"
                  target="_blank"
                >
                  {t("header.cta")}
                </ActionButtonA>

                <ActionButtonA
                  className="rn-btn d-xl-none"
                  href={link1}
                  id="client_center_1"
                  target="_blank"
                >
                  {t("header.customer-center")}
                </ActionButtonA>
              </div>
            </div>

            <div className="d-lg-none1 d-xxl-block">
              <Menu onNavigate={() => setWrapperOpen(false)} />
            </div>
          </div>

          <div
            className={classnames("header-btn d-flex d-lg-none d-xxl-block", {
              open: wrapperOpen,
            })}
          >
            <a
              className="rn-btn theme-color show-desktop"
              href={link3}
              id="acc_open_1"
              target="_blank"
              onMouseOver={() => setMouseOverCTA(true)}
              onMouseOut={() => setMouseOverCTA(false)}
            >
              {mouseOverCTA ? (
                <IconNewAccountRed className="" />
              ) : (
                <IconNewAccount className="" />
              )}
              <span>{t("header.cta")}</span>
            </a>
            {/* mobile */}
            <ActionButtonA
              className="rn-btn hide-desktop"
              href={link3}
              id="acc_open_1"
            >
              {t("header.cta")}
            </ActionButtonA>

            <ActionButtonA
              className="rn-btn hide-desktop"
              href={link1}
              id="client_center_1"
            >
              {t("header.customer-center")}
            </ActionButtonA>
          </div>
        </div>
      </div>

      <div>
        {/* Start Humberger Menu  */}
        <div className={classnames("humberger-menu", { open: wrapperOpen })}>
          <span onClick={menuTrigger} className="menutrigger">
            <IconHumbergerMenu />
          </span>
        </div>
        {/* End Humberger Menu  */}
        <div className={classnames("close-menu", { open: wrapperOpen })}>
          <span onClick={CLoseMenuTrigger} className="closeTrigger">
            <IconMenuClose />
          </span>
        </div>
        {/* Mobile language switcher */}
        <LanguageSwitcherSimple
          className={classnames({ open: wrapperOpen })}
          onLanguageSwitch={() => setWrapperOpen(false)}
        />
      </div>
    </header>
  );
};

export default Header;
