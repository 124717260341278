import { useTranslation } from "react-i18next";

const CgseCodeContent = () => {
  const { t } = useTranslation();
  return (
    <div className="cgse-code-content">
      <div className="container">
        <div className="ptb--150 pt_sm--50 pb_sm--75 pt_md--50 pb_md--75 pt_lg--75">
          <div className="row">
            <div className="col-12">
              <p className="mb--50 intro-text">
                {t("cgse_code.content.text.1")}
              </p>
              <table className="table">
                <thead>
                  <tr>
                    <th>{t("cgse_code.content.text.2")}</th>
                    <th>{t("cgse_code.content.text.3")}</th>
                    <th>{t("cgse_code.content.text.4")}</th>
                  </tr>
                </thead>
                <tbody className="">
                  <tr className="">
                    <td className="">{t("cgse_code.content.text.5")}</td>
                    <td className="">{t("cgse_code.content.text.6")}</td>
                    <td className="">{t("cgse_code.content.text.7")}</td>
                  </tr>
                  <tr className="">
                    <td className="">{t("cgse_code.content.text.8")}</td>
                    <td className="">{t("cgse_code.content.text.9")}</td>
                    <td className="">{t("cgse_code.content.text.10")}</td>
                  </tr>
                  <tr className="">
                    <td className="">{t("cgse_code.content.text.11")}</td>
                    <td className="">{t("cgse_code.content.text.12")}</td>
                    <td className="">{t("cgse_code.content.text.13")}</td>
                  </tr>
                  <tr className="">
                    <td className="">{t("cgse_code.content.text.14")}</td>
                    <td className="">{t("cgse_code.content.text.15")}</td>
                    <td className="">{t("cgse_code.content.text.16")}</td>
                  </tr>
                  <tr className="">
                    <td className="">{t("cgse_code.content.text.17")}</td>
                    <td className="">{t("cgse_code.content.text.18")}</td>
                    <td className="">{t("cgse_code.content.text.19")}</td>
                  </tr>
                  <tr className="">
                    <td className="">{t("cgse_code.content.text.20")}</td>
                    <td className="">{t("cgse_code.content.text.21")}</td>
                    <td className="">{t("cgse_code.content.text.22")}</td>
                  </tr>
                  <tr className="">
                    <td className="">{t("cgse_code.content.text.23")}</td>
                    <td className="">{t("cgse_code.content.text.24")}</td>
                    <td className="">{t("cgse_code.content.text.25")}</td>
                  </tr>
                  <tr className="">
                    <td className="">{t("cgse_code.content.text.26")}</td>
                    <td className="">{t("cgse_code.content.text.27")}</td>
                    <td className="">{t("cgse_code.content.text.28")}</td>
                  </tr>
                  <tr className="">
                    <td className="">{t("cgse_code.content.text.29")}</td>
                    <td className="">{t("cgse_code.content.text.30")}</td>
                    <td className="">{t("cgse_code.content.text.31")}</td>
                  </tr>
                  <tr className="">
                    <td className="">{t("cgse_code.content.text.32")}</td>
                    <td className="">{t("cgse_code.content.text.33")}</td>
                    <td className="" style={{ color: "#E21F26" }}>
                      {t("cgse_code.content.text.34")}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="row mt--150 mt_sm--50 mt_md--50">
            <div className="col-12 col-lg-12">
              <p className="mb--50 intro-text">
                {t("cgse_code.content.text.35")}
              </p>
              <table className="table">
                <thead>
                  <tr>
                    <th>{t("cgse_code.content.text.36")}</th>
                    <th>{t("cgse_code.content.text.37")}</th>
                  </tr>
                </thead>
                <tbody className="">
                  <tr className="">
                    <td className="">{t("cgse_code.content.text.38")}</td>
                    <td className="">{t("cgse_code.content.text.39")}</td>
                  </tr>
                  <tr className="">
                    <td className="">{t("cgse_code.content.text.40")}</td>
                    <td className="">{t("cgse_code.content.text.41")}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CgseCodeContent;
