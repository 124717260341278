import { useTranslation } from "react-i18next";
import SectionTitle from "../../components/partial/SectionTitle";
import license1 from "../../resources/licenses/license01.png";
import license2 from "../../resources/licenses/license02.png";
import license3 from "../../resources/licenses/license03.png";
import license4 from "../../resources/licenses/license04.png";
import license5 from "../../resources/licenses/license05.png";
import license6 from "../../resources/licenses/license06.png";
import ListLogo from "../../components/partial/ListLogo";

const AboutLicense = () => {
  const { t } = useTranslation();
  const list = Array(6)
    .fill("")
    .map((e, i) => {
      let icon;
      switch (i + 1) {
        case 1:
          icon = <img src={license1} alt={t(`license.list.title.${i}`)} />;
          break;
        case 2:
          icon = <img src={license2} alt={t(`license.list.title.${i}`)} />;
          break;
        case 3:
          icon = <img src={license3} alt={t(`license.list.title.${i}`)} />;
          break;
        case 4:
          icon = <img src={license4} alt={t(`license.list.title.${i}`)} />;
          break;
        case 5:
          icon = <img src={license5} alt={t(`license.list.title.${i}`)} />;
          break;
        case 6:
          icon = <img src={license6} alt={t(`license.list.title.${6}`)} />;
          break;
        default:
          icon = <img src={license1} alt={t(`license.list.title.${i}`)} />;
          break;
      }

      return {
        icon: icon,
        title: t(`license.list.title.${i + 1}`),
        description: t(`license.list.description.${i + 1}`),
      };
    });

  return (
    <div className="about-license ptb--150 bg-style--3 bg_image pt_sm--60 pb_sm--75 pt_md--60 pb_md--75 pt_lg--60 pb_lg--75 plr_lg--100">
      <div className="container">
        <div className="row justify-content-md-center">
          <div className="col-lg-8">
            <SectionTitle title={t("abtht.license.header")} />
          </div>
        </div>

        <div className="row justify-content-md-center">
          <ListLogo col="col-12 col-sm-4" items={list} />
        </div>
      </div>
    </div>
  );
};

export default AboutLicense;
