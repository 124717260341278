import classnames from "classnames";
import { CSSProperties, HTMLProps } from "react";
import { onlyLettersAndSpaces } from "../../helpers";
interface CallActionProps extends HTMLProps<HTMLElement> {
  tagline?: string;
  h1?: string;
  title?: string;
  description?: string;
  button?: React.ReactNode;
  textAlign?: "left" | "center" | "right";
  bgImage?: React.ReactNode;
  className?: string;
  style?: CSSProperties;
  children?: React.ReactNode;
}

const CallAction = (props: CallActionProps) => {
  const classNames = classnames(
    "call-to-action bg_image wrapper fix",
    props.className
  );

  const h1ClassNames = classnames("text-white", {
    "english-only": onlyLettersAndSpaces(props.h1!),
  });

  return (
    <div className={classNames} style={props.style}>
      {props.bgImage}
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className={`inner text-white text-center text-md-start`}>
              {props.tagline && <span>{props.tagline}</span>}
              {props.h1 && <h1 className={h1ClassNames}>{props.h1}</h1>}
              {props.title && <h2 className="text-white">{props.title}</h2>}
              {props.description && <p>{props.description}</p>}
              {props.button}
            </div>
            {props.children}
          </div>
        </div>
      </div>
    </div>
  );
};
export default CallAction;
