import classnames from "classnames";
import { t } from "i18next";
import { Link } from "react-router-dom";
import { HEADER_MENU } from "../../constants";
import { buildUrl, onlyLettersAndSpaces } from "../../helpers";
import { useTranslation } from "react-i18next";
import { useState } from "react";

interface MenuProp {
  onNavigate?: () => void;
}

const Menu = (props: MenuProp) => {
  const { t, i18n } = useTranslation();
  const [openIndex, setOpenIndex] = useState<number>(-1);
  const { onNavigate = () => {} } = props;

  const handleLvl1Click = (index: number) => {
    if (index === -1) {
      onNavigate();
    } else {
      if (index === openIndex) {
        setOpenIndex(-1);
      } else {
        setOpenIndex(index);
      }
    }
  };

  return (
    <nav className="mainmenunav d-lg-block">
      <ul className="mainmenu">
        {HEADER_MENU.map((lvl1, i) => {
          const classNames = classnames({
            "has-droupdown": lvl1.sub?.length,
            "open active": openIndex === i,
          });
          return (
            <li className={classNames} key={i}>
              <Link
                className={classnames({ "open active": openIndex === i })}
                to={buildUrl(lvl1.href, i18n.language)}
                target={lvl1.target}
                onClick={() => handleLvl1Click(lvl1.sub?.length ? i : -1)}
              >
                {t(lvl1.label)}
              </Link>
              {lvl1.sub && (
                <ul
                  className={classnames("submenu", { active: openIndex === i })}
                >
                  {lvl1.sub.map((lvl2, j) => {
                    const linkClassNames = classnames({
                      "english-only": onlyLettersAndSpaces(t(lvl2.label)),
                    });
                    return (
                      <li key={j}>
                        <Link
                          to={buildUrl(lvl2.href, i18n.language)}
                          target={lvl2.target}
                          onClick={props.onNavigate}
                          className={linkClassNames}
                        >
                          {t(lvl2.label)}
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              )}
            </li>
          );
        })}
      </ul>
    </nav>
  );
};

export default Menu;
