import { useTranslation } from "react-i18next";
import SectionTitle from "./SectionTitle";
import { ListSummaryProps } from "./ListSummaryType";
import { useState } from "react";
import classnames from "classnames";
import { Link } from "react-router-dom";

const ListSummary = ({
  list,
  filters,
  className,
  readAllText,
  readAllLink = "#",
  onItemClicked = () => {},
  emptyDataMessage = "No data available",
}: ListSummaryProps) => {
  const [category, setCategory] = useState(filters.at(0)?.name);

  return (
    <div className={`list-summary ${className}`}>
      <div className="container">
        {readAllText && (
          <div className="row">
            <div className="col-12">
              <div className="text-end mb--20">
                <Link to={readAllLink}>{readAllText}</Link>
              </div>
            </div>
          </div>
        )}

        <div className="row justify-content-md-center flex-column flex-sm-row">
          <div className="col-12 col-sm-3">
            <ul className="filters liststyle mb_sm--75">
              {filters.map((filter, i) => {
                const className = classnames("mb--30 mb_md--25", {
                  active: category === filter.name,
                });
                return (
                  <li
                    className={className}
                    key={i}
                    onClick={() => setCategory(filter.name)}
                  >
                    <SectionTitle title={filter.title} />
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="col-12 col-sm-9">
            {list.filter((item, i) => {
              return item.category === category;
            }).length === 0 ? (
              <p className="text-center">{emptyDataMessage}</p>
            ) : (
              <div className="summary-table">
                {list.map((row, i) => (
                  <div
                    className={`summary-row ${
                      row.category === category ? "" : "hidden"
                    }`}
                    key={i}
                    onClick={() => onItemClicked(row.id!)}
                  >
                    <div className="summary-cell date">{row.date}</div>
                    <div className="summary-cell title">{row.title}</div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListSummary;
