import { useTranslation } from "react-i18next";
import PageHelment from "../components/common/Helment";
import CallAction from "../components/partial/CallAction";
import logo from "../resources/logo/logo_short_white.png";
import banner03 from "../resources/banner/banner03.jpg";
import ActionButton, {
  ActionButtonA,
} from "../components/partial/ActionButton";
import Breadcrumb from "../components/header/Breadcrumb";
import IntroDark from "../blocks/intro/IntroDark";
import AboutIntroContent from "../components/partial/AboutIntroContent";
import AboutMap from "../blocks/about/AboutMap";
import AboutLicense from "../blocks/about/AboutLicense";

const AboutHT = () => {
  const { t } = useTranslation();
  return (
    <>
      <PageHelment
        title={`${t("common.meta.prefix")} | ${t("abtht.meta.title")} | ${t(
          "common.meta.suffix"
        )}`}
        description={t("abtht.meta.description")}
      />
      <CallAction
        bgImage={<img src={banner03} alt="Call to Action Background" />}
        className="ptb--100"
        textAlign="left"
        tagline={t("abtht.banner.tagline")}
        h1={t("abtht.banner.title")}
        description={t("abtht.banner.description")}
        button={
          <ActionButtonA
            target="_blank"
            href={`/assets/download/Group_Brochure_SC.pdf`}
            className="mt--40"
            id="group_info_1"
          >
            {t("abtht.banner.button.text")}
          </ActionButtonA>
        }
      />
      <Breadcrumb
        parentPage={t("abtht.breadcrumb.level.1")}
        currentPage={t("abtht.breadcrumb.level.2")}
      />

      <IntroDark
        title={t("abtht.about.header")}
        description={t("abtht.about.desc")}
        image={<img src={logo} alt={t("site.title")} />}
      >
        <AboutIntroContent />
      </IntroDark>
      <AboutMap />
      <AboutLicense />
    </>
  );
};

export default AboutHT;
