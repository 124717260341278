import { Icon } from "@ailibs/feather-react-ts";
import classnames from "classnames";
import { useState } from "react";
import { Link, LinkProps } from "react-router-dom";

const ActionButton = (props: LinkProps) => {
  const classNames = classnames(props.className, "action-button");
  const [isOnHover, setIsOnHover] = useState(false);
  const isExternal = props.to?.toString().startsWith("http");
  return (
    <Link
      {...props}
      className={classNames}
      onMouseOver={() => setIsOnHover(true)}
      onMouseOut={() => setIsOnHover(false)}
      target={props.target ? props.target : isExternal ? "_blank" : "_self"}
    >
      <span>{props.children}</span>
      <Icon
        name="chevron-right"
        color={
          isOnHover
            ? props.className?.indexOf("secondary")! > -1
              ? "#ffffff"
              : props.className?.indexOf("light")! > -1
              ? "#ffffff"
              : "#e30e18"
            : "#fff"
        }
        size={25}
      />
    </Link>
  );
};

export const ActionButtonA = (
  props: React.AnchorHTMLAttributes<HTMLAnchorElement>
) => {
  const classNames = classnames(props.className, "action-button");
  const [isOnHover, setIsOnHover] = useState(false);
  const isExternal = props.href?.toString().startsWith("http");
  return (
    <a
      {...props}
      className={classNames}
      onMouseOver={() => setIsOnHover(true)}
      onMouseOut={() => setIsOnHover(false)}
      target={props.target ? props.target : isExternal ? "_blank" : "_self"}
    >
      <span>{props.children}</span>
      <Icon
        name="chevron-right"
        color={
          isOnHover
            ? props.className?.indexOf("secondary")! > -1
              ? "#ffffff"
              : props.className?.indexOf("light")! > -1
              ? "#ffffff"
              : "#e30e18"
            : "#fff"
        }
        size={25}
      />
    </a>
  );
};

export default ActionButton;
