import { useTranslation } from "react-i18next";
import img01 from "../../resources/about-content/01.jpg";
import img02 from "../../resources/about-content/02.jpg";
import { Content } from "../../components/partial/TwoColumnsContent.type";
import TwoColumnsContent from "../../components/partial/TwoColumnsContent";

const AboutHblContent = () => {
  const { t } = useTranslation();

  const data: Content[] = [
    {
      title: t("aboutHBL.content.title.1"),
      description: t("aboutHBL.content.description.1"),
      image: (
        <img
          src={img01}
          className="img img-br"
          alt={t("aboutHBL.content.title.1")}
        />
      ),
    },
    {
      title: t("aboutHBL.content.title.2"),
      description: t("aboutHBL.content.description.2"),
      image: (
        <img
          src={img02}
          className="img img-br"
          alt={t("aboutHBL.content.title.2")}
        />
      ),
    },
  ];

  return <TwoColumnsContent className="about-hbl-content" contents={data} />;
};

export default AboutHblContent;
