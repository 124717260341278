import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";

// Create Import File
import App from "./components/common/App";
import { routes } from "./routes";
import "./i18n";
import "./index.scss";
import i18n from "./i18n";

const defaultLocale = i18n.languages.at(0) || "tc";

const router = createBrowserRouter(
  [
    {
      path: "/",
      element: <Navigate to={defaultLocale} />,
    },
    {
      path: "/:locale/",
      element: <App />,
      //errorElement: <div>Sorry, error.</div>,
      children: routes,
    },
  ],
  { basename: "/" }
  // for demo only
  // { basename: "/hantecbullion" }
);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <Suspense fallback="loading">
      <HelmetProvider>
        <RouterProvider router={router} />
      </HelmetProvider>
    </Suspense>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
