import { useTranslation } from "react-i18next";
import PageHelment from "../components/common/Helment";
import Breadcrumb from "../components/header/Breadcrumb";
import IbswBlock from "../blocks/ibsw/IbswBlock";
import ActivitySlider from "../blocks/slider/ActivitySlider";

const IBSW = () => {
  const { t } = useTranslation();
  return (
    <>
      <PageHelment
        title={`${t("common.meta.prefix")} | ${t("IBSW.meta.title")} | ${t(
          "common.meta.suffix"
        )}`}
        description={t("IBSW.meta.description")}
      />
      <div className="activity-banner-wrapper">
        <ActivitySlider />
      </div>
      <Breadcrumb
        parentPage={t("IBSW.breadcrumb.level.1")}
        currentPage={t("IBSW.breadcrumb.level.2")}
      />
      <IbswBlock />
    </>
  );
};

export default IBSW;
