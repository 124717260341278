import { Trans, useTranslation } from "react-i18next";
import SectionTitle from "../../components/partial/SectionTitle";
import { Link } from "react-router-dom";

const ContactMap = () => {
  const { t, i18n } = useTranslation();
  return (
    <div className="contact-map">
      <div className="pt--100 pb--150 pt_sm--60 pb_sm--75 pt_md--60 pb_md--75">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-5">
              <div className="mb--40 mb_sm--60 mb_md--25">
                <SectionTitle title={t("contact.intro.header")} />
              </div>

              <p className="mb_sm--50">
                {t("contact.intro.address.1")}
                <br />
                {t("contact.intro.address.2")}
                <br />
                <Trans
                  i18nKey="contact.intro.address.3"
                  components={{
                    italic: <i />,
                    bold: <strong />,
                    red: <span className="theme-color" />,
                    atag: <a />,
                  }}
                />
                <br />
                <Trans
                  i18nKey="contact.intro.address.4"
                  components={{
                    italic: <i />,
                    bold: <strong />,
                    red: <span className="theme-color" />,
                    atag: <a />,
                  }}
                />
                <br />
                <Trans
                  i18nKey="contact.intro.address.5"
                  components={{
                    italic: <i />,
                    bold: <strong />,
                    red: <span className="theme-color" />,
                    atag: <a />,
                  }}
                />
              </p>
            </div>
            {/*  */}
            <div className="col-12 col-md-7">
              <div className="google-map-wrapper wrapper">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d922.9501927991806!2d114.1524865!3d22.2855355!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3404007cf7c89487%3A0xa460ce5bd05bd651!2z5Lqo6YGU6ZuG5ZyYIEhhbnRlYyBHcm91cA!5e0!3m2!1szh-TW!2shk!4v1700202150614!5m2!1szh-TW!2shk"
                  style={{ border: "0" }}
                  loading="lazy"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactMap;
