import { useTranslation } from "react-i18next";
import IntroDark from "../blocks/intro/IntroDark";
import PageHelment from "../components/common/Helment";
import CallAction from "../components/partial/CallAction";
import banner10 from "../resources/banner/banner10.jpg";
import Breadcrumb from "../components/header/Breadcrumb";
import CgseCodeContent from "../blocks/trade/CgseCodeContent";

const TradeCgseCode = () => {
  const { t } = useTranslation();
  return (
    <>
      <PageHelment
        title={`${t("common.meta.prefix")} | ${t("cgse_code.meta.title")} | ${t(
          "common.meta.suffix"
        )}`}
        description={t("cgse_code.meta.description")}
      />
      <CallAction
        bgImage={<img src={banner10} alt={t("cgse_code.meta.title")} />}
        className="ptb--100"
        textAlign="left"
        tagline={t("cgse_code.banner.tagline")}
        h1={t("cgse_code.banner.title")}
        description={t("cgse_code.banner.description")}
      />
      <Breadcrumb
        parentPage={t("cgse_code.breadcrumb.level.1")}
        currentPage={t("cgse_code.breadcrumb.level.2")}
      />

      <IntroDark
        title={t("cgse_code.intro.header")}
        description={t("cgse_code.intro.desc")}
      >
        <div className="row gx-5 mt--30">
          <div className="col-12 col-md-8">
            <div className="intro-dark-description">
              {t("cgse_code.intro.desc.p2")}
            </div>
          </div>
        </div>
      </IntroDark>
      <CgseCodeContent />
    </>
  );
};

export default TradeCgseCode;
