import { useTranslation } from "react-i18next";
import PageHelment from "../components/common/Helment";
import ActionButton from "../components/partial/ActionButton";

const Page404 = () => {
  const { t, i18n } = useTranslation();

  return (
    <>
      <PageHelment
        title={`${t("common.meta.prefix")} | ${t("404.page.content")} | ${t(
          "common.meta.suffix"
        )}`}
        description={t("404.page.content")}
      />
      <div className="content-404 d-flex justify-content-center align-items-center ptb--75 plr--20">
        <h1 className="text-center">{t("404.page.content")}</h1>
        <ActionButton className="light" to={`/${i18n.language}`}>
          {t("404.page.button.text")}
        </ActionButton>
      </div>
    </>
  );
};

export default Page404;
