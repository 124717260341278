import Slider from "react-slick";
import ActionButton, {
  ActionButtonA,
} from "../../components/partial/ActionButton";
import { SlideProps } from "./types";
import useFetch from "use-http";
import { useEffect, useState } from "react";
import { LANGUAGES } from "../../constants";
import { useTranslation } from "react-i18next";

// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { htmlDecode } from "../../helpers";

const ActivitySlider = () => {
  const { i18n } = useTranslation();
  const [data, setData] = useState({ banners: [] });
  const { get, post, response, loading, error } = useFetch(
    process.env.REACT_APP_BACKEND_API_URL
  );

  // get banners function
  const getBanner = async () => {
    // get the language short code
    const languageCode =
      LANGUAGES.find((l) => l.locale === i18n.language)?.code || "tc";

    const results = await get(`/announcement-banners/${languageCode}`);
    if (response.ok) setData(results);
  };

  useEffect(() => {
    getBanner();
  }, [i18n.language]);

  const slideSlick = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    arrows: true,
    fade: true,
    easing: "fade",
    adaptiveHeight: true,
  };

  return (
    <div className="slider-wrapper">
      <div className="slider-activation">
        {data.banners.length > 0 && (
          <Slider className="rn-slick-dot dot-light" {...slideSlick}>
            {data.banners.map((value: SlideProps, index: number) => (
              <div key={index}>
                <div
                  className={`slide slider-box-content without-overlay d-flex align-items-center justify-content-center bg_image`}
                  style={{
                    backgroundImage: `url(${value.image})`,
                  }}
                >
                  <div className="container-sm">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className={`inner ${value.text_position}`}>
                          {value.tagline && (
                            <span className="tagline">{value.tagline}</span>
                          )}
                          {value.title && (
                            <h1 className="title">{value.title}</h1>
                          )}
                          {value.text && (
                            <div
                              className="description"
                              dangerouslySetInnerHTML={{
                                __html: htmlDecode(value.text),
                              }}
                            />
                          )}

                          <div className="slide-btn">
                            {value.button_link_1 && value.button_text_1 && (
                              <ActionButtonA href={value.button_link_1}>
                                {value.button_text_1}
                              </ActionButtonA>
                            )}
                            {value.button_link_2 && value.button_text_2 && (
                              <ActionButtonA href={value.button_link_2}>
                                {value.button_text_2}
                              </ActionButtonA>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        )}
      </div>
    </div>
  );
};

export default ActivitySlider;
