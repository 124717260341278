import { useTranslation } from "react-i18next";
import SectionTitle from "../../components/partial/SectionTitle";
import img02 from "../../resources/platforms/trading_central_screen_02.png";
import img03 from "../../resources/platforms/trading_central_screen_03.png";
import img04 from "../../resources/platforms/trading_central_screen_04.png";
import img05 from "../../resources/platforms/trading_central_screen_05.png";
import img06 from "../../resources/platforms/trading_central_screen_06.png";
import bg29 from "../../resources/background/bg29.png";
import other01 from "../../resources/platforms/trading_central_others_01.png";
import other02 from "../../resources/platforms/trading_central_others_02.png";
import other03 from "../../resources/platforms/trading_central_others_03.png";
import other04 from "../../resources/platforms/trading_central_others_04.png";
import other05 from "../../resources/platforms/trading_central_others_05.png";
import other06 from "../../resources/platforms/trading_central_others_06.png";

import CallAction from "../../components/partial/CallAction";

const TradingCentralSection2 = () => {
  const { t, i18n } = useTranslation();
  return (
    <div className="trading-central-section-2">
      <section className="bg-style--3 bg_image">
        <div className="ptb--150 pt_sm--60 pt_md--60 pb_sm--75 pb_md--75">
          <div className="container">
            <SectionTitle title={t("trading_central.content.2.header.1")} />
            <div className="row justify-content-center">
              <div className="col-12 col-lg-10">
                <p className="mt--40 mt_sm--60 text-center text-md-start">
                  {t("trading_central.content.2.description.1")}
                </p>
              </div>
            </div>

            <div className="mt--100 mt_sm--50 text-center">
              <img
                src={img02}
                style={{ width: "100%" }}
                alt={t("trading_central.content.2.header.1")}
              />
            </div>
          </div>
        </div>
      </section>
      <section className="">
        <div className="ptb--150 pt_sm--60 pt_md--60 pb_sm--75 pb_md--75">
          <div className="container">
            <SectionTitle title={t("trading_central.content.2.header.2")} />
            <div className="row justify-content-center">
              <div className="col-12 col-lg-10">
                <p className="mt--40 mt_sm--60 text-center text-md-start">
                  {t("trading_central.content.2.description.2")}
                </p>
              </div>
            </div>

            <div className="mt--100 mt_sm--50 text-center">
              <img
                src={img03}
                style={{ width: "100%" }}
                alt={t("trading_central.content.2.header.2")}
              />
            </div>
            <div className="mt--150 mt_sm--50 mt_md--50 text-center">
              <img
                src={img04}
                style={{ width: "100%" }}
                alt={t("trading_central.content.2.header.2")}
              />
            </div>
          </div>
        </div>
      </section>
      <section className="bg-style--28 bg_image">
        <div className="ptb--150 pt_sm--60 pt_md--60 pb_sm--75 pb_md--75">
          <div className="container">
            <SectionTitle title={t("trading_central.content.2.header.3")} />
            <div className="row justify-content-center">
              <div className="col-12 col-lg-10">
                <p className="mt--40 mt_sm--60 text-center text-md-start">
                  {t("trading_central.content.2.description.3")}
                </p>
              </div>
            </div>

            <div className="mt--100 mt_sm--50 mt_md--50 text-center">
              <img
                src={img05}
                style={{ width: "100%" }}
                alt={t("trading_central.content.2.header.3")}
              />
            </div>
            <div className="mt--150 mt_sm--50 mt_md--50 text-center">
              <img
                src={img06}
                style={{ width: "100%" }}
                alt={t("trading_central.content.2.header.3")}
              />
            </div>
          </div>
        </div>
      </section>

      <CallAction className="text-white" bgImage={<img src={bg29} />}>
        <>
          <SectionTitle title={t("trading_central.content.3.header")} />
          <div className="row justify-content-center">
            <div className="col-12 col-lg-10">
              <div className="mt--40 mt_sm--60 text-center">
                <p>{t("trading_central.content.3.p.1")}</p>
                <p>{t("trading_central.content.3.p.2")}</p>
              </div>
            </div>
          </div>
        </>
      </CallAction>

      <section className="">
        <div className="ptb--150 pt_sm--60 pt_md--60 pb_sm--75 pb_md--75">
          <div className="container">
            <SectionTitle title={t("trading_central.content.4.header")} />
            <div className="row justify-content-center">
              <div className="col-12 col-lg-10">
                <p className="mt--40 mt_sm--60 text-center text-md-start">
                  {t("trading_central.content.4.description")}
                </p>
              </div>
            </div>

            <div className="mt--100 mt_sm--50 text-center">
              <table className="table">
                <thead>
                  <tr>
                    <th colSpan={3}>{t("trading_central.content.4.text.1")}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <img src={other01} alt="" />
                    </td>
                    <td>
                      <img src={other02} alt="" />
                    </td>
                    <td>
                      <img src={other03} alt="" />
                    </td>
                  </tr>
                </tbody>
                <thead>
                  <tr>
                    <th colSpan={3}>{t("trading_central.content.4.text.2")}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <img src={other04} alt="" />
                    </td>
                    <td>
                      <img src={other05} alt="" />
                    </td>
                    <td>
                      <img src={other06} alt="" />
                    </td>
                  </tr>
                </tbody>
              </table>

              <div className="mt--50">
                <p>{t("trading_central.content.4.text.3")}</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default TradingCentralSection2;
