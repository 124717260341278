import { useTranslation } from "react-i18next";
import PageHelment from "../components/common/Helment";
import Breadcrumb from "../components/header/Breadcrumb";
import CallAction from "../components/partial/CallAction";
import banner24 from "../resources/banner/banner24.jpg";
import egoldLogo from "../resources/platforms/egold.png";
import IntroDark from "../blocks/intro/IntroDark";
import EGoldContent from "../blocks/platform/EGoldContent";
import { ReactComponent as LogoIOS } from "../../src/resources/svg/download_ios.svg";
import { ReactComponent as LogoAndroid } from "../../src/resources/svg/download_android.svg";
import { ReactComponent as LogoWin } from "../../src/resources/svg/download_win.svg";

const DownloadIcons = () => {
  return (
    <div className="d-flex justify-content-center justify-content-md-start flex-wrap mt--50 mb--20 mb_sm--50">
      <div className="icons mr_sm--0 mr--30">
        <a
          target="_blank"
          href="https://apps.apple.com/hk/app/hantec-e-gold/id446342629"
        >
          <LogoIOS />
        </a>
      </div>
      <div className="icons mr_sm--0 mr--30">
        <a
          target="_blank"
          href="https://www.hantecbullion.com/downloads/egold/android/Hantec-eGold.apk"
        >
          <LogoAndroid />
        </a>
      </div>
      <div className="icons mr_sm--0 mr--30">
        <a
          target="_blank"
          href="https://www.hantecbullion.com/downloads/egold/pc/HTS_HBL_Setup_Full.exe"
        >
          <LogoWin />
        </a>
      </div>
    </div>
  );
};

const PlatformEGold = () => {
  const { t } = useTranslation();
  return (
    <>
      <PageHelment
        title={`${t("common.meta.prefix")} | ${t("egold.meta.title")} | ${t(
          "common.meta.suffix"
        )}`}
        description={t("egold.meta.description")}
      />
      <CallAction
        bgImage={<img src={banner24} alt={t("egold.meta.title")} />}
        className="ptb--100"
        textAlign="left"
        tagline={t("egold.banner.tagline")}
        h1={t("egold.banner.title")}
        description={t("egold.banner.description")}
      />
      <Breadcrumb
        parentPage={t("egold.breadcrumb.level.1")}
        currentPage={t("egold.breadcrumb.level.2")}
      />
      <IntroDark
        title={t("egold.intro.header")}
        description={t("egold.intro.description")}
        image={
          <img
            src={egoldLogo}
            alt={t("egold.meta.title")}
            style={{ maxHeight: "150px" }}
          />
        }
        hasDownlod={true}
      >
        <DownloadIcons />
      </IntroDark>
      <EGoldContent />
    </>
  );
};

export default PlatformEGold;
