import { useTranslation } from "react-i18next";
import PageHelment from "../../components/common/Helment";
import SectionTitle from "../../components/partial/SectionTitle";

const PrivacyPolicy = () => {
  const { t } = useTranslation();
  return (
    <>
      <PageHelment
        title={`${t("common.meta.prefix")} | ${t(
          "legal.privacy-policy.meta.title"
        )}`}
        description={t("legal.privacy-policy.meta.description")}
      />
      <div className="legal-content pt--130 pb--150 pt_sm--60 pb_sm--75 pt_md--60 pb_md--75 pt_lg--60 pb_lg--75">
        <div className="container">
          <div className="text-left text-sm-center mb--60">
            <SectionTitle title={t("legal.privacy-policy.page.title")} />
          </div>
          <p className="mt--75">{t("legal.privacy-policy.page.description")}</p>
          {/*  */}
          <section>
            <div className="mt--75 mt_sm--50 mt_md--50 mt_lg--50">
              <h3>{t("legal.privacy-policy.section.1.header")}</h3>
              <div>
                <p>{t("legal.privacy-policy.section.1.text.1")}</p>
                <p>{t("legal.privacy-policy.section.1.text.2")}</p>
                <p>{t("legal.privacy-policy.section.1.text.3")}</p>
              </div>
            </div>
          </section>
          {/*  */}
          <section>
            <div className="mt--75 mt_sm--50 mt_md--50 mt_lg--50">
              <h3>{t("legal.privacy-policy.section.2.header")}</h3>
              <div>
                <ul>
                  <li>{t("legal.privacy-policy.section.2.text.1")}</li>
                  <li>{t("legal.privacy-policy.section.2.text.2")}</li>
                  <li>{t("legal.privacy-policy.section.2.text.3")}</li>
                  <li>{t("legal.privacy-policy.section.2.text.4")}</li>
                  <li>{t("legal.privacy-policy.section.2.text.5")}</li>
                </ul>
              </div>
            </div>
          </section>
          {/*  */}
          <section>
            <div className="mt--75 mt_sm--50 mt_md--50 mt_lg--50">
              <h3>{t("legal.privacy-policy.section.3.header")}</h3>
              <div>
                <ul>
                  <li>{t("legal.privacy-policy.section.3.text.1")}</li>
                  <li>{t("legal.privacy-policy.section.3.text.2")}</li>
                  <li>{t("legal.privacy-policy.section.3.text.3")}</li>
                  <li>{t("legal.privacy-policy.section.3.text.4")}</li>
                </ul>
              </div>
            </div>
          </section>
          {/*  */}
          <section>
            <div className="mt--75 mt_sm--50 mt_md--50 mt_lg--50">
              <h3>{t("legal.privacy-policy.section.4.header")}</h3>
              <div>
                <p>{t("legal.privacy-policy.section.4.text.1")}</p>
                <p>{t("legal.privacy-policy.section.4.text.2")}</p>
                <ul>
                  <li>{t("legal.privacy-policy.section.4.text.3")}</li>
                  <li>{t("legal.privacy-policy.section.4.text.4")}</li>
                  <li>{t("legal.privacy-policy.section.4.text.5")}</li>
                  <li>{t("legal.privacy-policy.section.4.text.6")}</li>
                </ul>
                <p>{t("legal.privacy-policy.section.4.text.7")}</p>
                <p>
                  {t("legal.privacy-policy.section.4.text.8")}
                  <br />
                  {t("legal.privacy-policy.section.4.text.9")}
                  <br />
                  {t("legal.privacy-policy.section.4.text.10")}
                  <br />
                  {t("legal.privacy-policy.section.4.text.11")}
                  <br />
                  {t("legal.privacy-policy.section.4.text.12")}
                  <br />
                </p>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicy;
