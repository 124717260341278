import { useTranslation } from "react-i18next";
import PageHelment from "../../components/common/Helment";
import SectionTitle from "../../components/partial/SectionTitle";

const RiskDisclosures = () => {
  const { t } = useTranslation();
  return (
    <>
      <PageHelment
        title={`${t("common.meta.prefix")} | ${t(
          "legal.risk-disclosures.meta.title"
        )}`}
        description={t("legal.risk-disclosures.meta.description")}
      />
      <div className="legal-content pt--130 pb--150 pt_sm--60 pb_sm--75 pt_md--60 pb_md--75 pt_lg--60 pb_lg--75">
        <div className="container">
          <div className="text-left text-sm-center mb--60">
            <SectionTitle title={t("legal.risk-disclosures.page.title")} />
          </div>
          <section>
            <div className="mt--75 mt_sm--50 mt_md--50 mt_lg--50">
              <h3>{t("legal.risk-disclosures.section.1.header")}</h3>
              <div>
                <p>{t("legal.risk-disclosures.section.1.text.1")}</p>
                <p>{t("legal.risk-disclosures.section.1.text.2")}</p>
                <p>{t("legal.risk-disclosures.section.1.text.3")}</p>
              </div>
            </div>
          </section>
          {/*  */}
          <section>
            <div className="mt--75 mt_sm--50 mt_md--50 mt_lg--50">
              <h3>{t("legal.risk-disclosures.section.2.header")}</h3>
              <div>
                <p>{t("legal.risk-disclosures.section.2.text.1")}</p>
                <p>{t("legal.risk-disclosures.section.2.text.2")}</p>
              </div>
            </div>
          </section>
          {/*  */}
          <section>
            <div className="mt--75 mt_sm--50 mt_md--50 mt_lg--50">
              <h3>{t("legal.risk-disclosures.section.3.header")}</h3>
              <div>
                <p>{t("legal.risk-disclosures.section.3.text.1")}</p>
                <p>{t("legal.risk-disclosures.section.3.text.2")}</p>
                <p>{t("legal.risk-disclosures.section.3.text.3")}</p>
                <p>{t("legal.risk-disclosures.section.3.text.4")}</p>
              </div>
            </div>
          </section>
          {/*  */}
          <section>
            <div className="mt--75 mt_sm--50 mt_md--50 mt_lg--50">
              <h3>{t("legal.risk-disclosures.section.4.header")}</h3>
              <div>
                <p>{t("legal.risk-disclosures.section.4.text.1")}</p>
                <p>{t("legal.risk-disclosures.section.4.text.2")}</p>
              </div>
            </div>
          </section>
          {/*  */}
          <section>
            <div className="mt--75 mt_sm--50 mt_md--50 mt_lg--50">
              <h3>{t("legal.risk-disclosures.section.5.header")}</h3>
              <div>
                <p>{t("legal.risk-disclosures.section.5.text.1")}</p>
                <h4>{t("legal.risk-disclosures.section.5.text.2")}</h4>
                <p>{t("legal.risk-disclosures.section.5.text.3")}</p>
                <h4>{t("legal.risk-disclosures.section.5.text.4")}</h4>
                <ul>
                  <li>{t("legal.risk-disclosures.section.5.text.5")}</li>
                  <li>{t("legal.risk-disclosures.section.5.text.6")}</li>
                  <li>{t("legal.risk-disclosures.section.5.text.7")}</li>
                </ul>
                <h4>{t("legal.risk-disclosures.section.5.text.8")}</h4>
                <p>{t("legal.risk-disclosures.section.5.text.9")}</p>
                <h4>{t("legal.risk-disclosures.section.5.text.10")}</h4>
                <p>{t("legal.risk-disclosures.section.5.text.11")}</p>
                <h4>{t("legal.risk-disclosures.section.5.text.12")}</h4>
                <ul>
                  <li>{t("legal.risk-disclosures.section.5.text.13")}</li>
                  <li>{t("legal.risk-disclosures.section.5.text.14")}</li>
                  <li>{t("legal.risk-disclosures.section.5.text.15")}</li>
                </ul>
                <h4>{t("legal.risk-disclosures.section.5.text.16")}</h4>
                <p>{t("legal.risk-disclosures.section.5.text.17")}</p>
                <h4>{t("legal.risk-disclosures.section.5.text.18")}</h4>
                <ul>
                  <li>{t("legal.risk-disclosures.section.5.text.19")}</li>
                  <li>{t("legal.risk-disclosures.section.5.text.20")}</li>
                  <li>{t("legal.risk-disclosures.section.5.text.21")}</li>
                </ul>
                <h4>{t("legal.risk-disclosures.section.5.text.22")}</h4>
                <p>{t("legal.risk-disclosures.section.5.text.23")}</p>
                <h4>{t("legal.risk-disclosures.section.5.text.24")}</h4>
                <p>{t("legal.risk-disclosures.section.5.text.25")}</p>
                <h4>{t("legal.risk-disclosures.section.5.text.26")}</h4>
                <p>{t("legal.risk-disclosures.section.5.text.27")}</p>
              </div>
            </div>
          </section>
          {/*  */}
          <section>
            <div className="mt--75 mt_sm--50 mt_md--50 mt_lg--50">
              <h3>{t("legal.risk-disclosures.section.6.header")}</h3>
              <div>
                <ul>
                  <li>{t("legal.risk-disclosures.section.6.text.1")}</li>
                  <li>{t("legal.risk-disclosures.section.6.text.2")}</li>
                  <li>{t("legal.risk-disclosures.section.6.text.3")}</li>
                  <li>{t("legal.risk-disclosures.section.6.text.4")}</li>
                  <li>{t("legal.risk-disclosures.section.6.text.5")}</li>
                  <li>{t("legal.risk-disclosures.section.6.text.6")}</li>
                  <li>{t("legal.risk-disclosures.section.6.text.7")}</li>
                  <li>{t("legal.risk-disclosures.section.6.text.8")}</li>
                  <li>{t("legal.risk-disclosures.section.6.text.9")}</li>
                  <li>{t("legal.risk-disclosures.section.6.text.10")}</li>
                  <li>{t("legal.risk-disclosures.section.6.text.11")}</li>
                  <li>{t("legal.risk-disclosures.section.6.text.12")}</li>
                  <li>{t("legal.risk-disclosures.section.6.text.13")}</li>
                </ul>
              </div>
            </div>
          </section>
          {/*  */}
          <section>
            <div className="mt--75 mt_sm--50 mt_md--50 mt_lg--50">
              <h3>{t("legal.risk-disclosures.section.7.header")}</h3>
              <div>
                <p>{t("legal.risk-disclosures.section.7.text.1")}</p>
                <h4>{t("legal.risk-disclosures.section.7.text.2")}</h4>
                <p>{t("legal.risk-disclosures.section.7.text.3")}</p>
                <h4>{t("legal.risk-disclosures.section.7.text.4")}</h4>
                <ul>
                  <li>{t("legal.risk-disclosures.section.7.text.5")}</li>
                  <li>{t("legal.risk-disclosures.section.7.text.6")}</li>
                </ul>
                <h4>{t("legal.risk-disclosures.section.7.text.7")}</h4>
                <ul>
                  <li>{t("legal.risk-disclosures.section.7.text.8")}</li>
                  <li>{t("legal.risk-disclosures.section.7.text.9")}</li>
                </ul>
                <h4>{t("legal.risk-disclosures.section.7.text.10")}</h4>
                <p>{t("legal.risk-disclosures.section.7.text.11")}</p>
                <h4>{t("legal.risk-disclosures.section.7.text.12")}</h4>
                <p>{t("legal.risk-disclosures.section.7.text.13")}</p>
                <ul>
                  <li>{t("legal.risk-disclosures.section.7.text.14")}</li>
                  <li>{t("legal.risk-disclosures.section.7.text.15")}</li>
                </ul>
                <p>{t("legal.risk-disclosures.section.7.text.16")}</p>
              </div>
            </div>
          </section>
          {/*  */}
          <section>
            <div className="mt--75 mt_sm--50 mt_md--50 mt_lg--50">
              <h3>{t("legal.risk-disclosures.section.8.header")}</h3>
              <div>
                <ul>
                  <li>{t("legal.risk-disclosures.section.8.text.1")}</li>
                  <li>{t("legal.risk-disclosures.section.8.text.2")}</li>
                </ul>
              </div>
            </div>
          </section>
          {/*  */}
          <section>
            <div className="mt--75 mt_sm--50 mt_md--50 mt_lg--50">
              <h3>{t("legal.risk-disclosures.section.9.header")}</h3>
              <div>
                <p>{t("legal.risk-disclosures.section.9.text.1")}</p>
              </div>
            </div>
          </section>
          {/*  */}
          <section>
            <div className="mt--75 mt_sm--50 mt_md--50 mt_lg--50">
              <h3>{t("legal.risk-disclosures.section.10.header")}</h3>
              <div>
                <p>{t("legal.risk-disclosures.section.10.text.1")}</p>
              </div>
            </div>
          </section>
          {/*  */}
          <section>
            <div className="mt--75 mt_sm--50 mt_md--50 mt_lg--50">
              <h3>{t("legal.risk-disclosures.section.11.header")}</h3>
              <div>
                <p>{t("legal.risk-disclosures.section.11.text.1")}</p>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default RiskDisclosures;
