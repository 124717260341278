import { useTranslation } from "react-i18next";
import SectionTitle from "../../components/partial/SectionTitle";
import { ReactComponent as MapTC } from "../../resources/svg/graph_map.svg";
import { ReactComponent as MapMobileTC } from "../../resources/svg/graph_map_mobile.svg";
import { ReactComponent as MapSC } from "../../resources/svg/graph_map_sc.svg";
import { ReactComponent as MapMobileSC } from "../../resources/svg/graph_map_mobile_sc.svg";
import useWindowSize from "../../hooks/useWindowSize";

const AboutMap = () => {
  const { t, i18n } = useTranslation();
  const size = useWindowSize();

  const Map = () => {
    return i18n.language === "tc" ? <MapTC /> : <MapSC />;
  };
  const MapMobile = () => {
    return i18n.language === "tc" ? <MapMobileTC /> : <MapMobileSC />;
  };

  return (
    <div className="about-map">
      <div className="container ptb--150 pt_sm--60 pb_sm--50 pt_md--60 pb_md--50 pt_lg--60 pb_lg--50">
        <div className="row justify-content-md-center">
          <div className="col-lg-8">
            <div className="text-center">
              <div className="mb--30">
                <SectionTitle title={t("abtht.map.header")} />
              </div>
              <p>{t("abtht.map.desc")}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fuild">
        <div className="row justify-content-md-center">
          <div className="col-12 text-center">
            {size.innerWidth > 991 ? <Map /> : <MapMobile />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutMap;
