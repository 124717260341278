import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";

interface HelmentProps {
  title: string;
  description?: string;
}

const PageHelment = ({ title, description }: HelmentProps) => {
  const { t } = useTranslation();
  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="keywords" content={t("site.keywords")} />
      </Helmet>
    </>
  );
};

export default PageHelment;
