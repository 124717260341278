import { useTranslation } from "react-i18next";
import { ReactComponent as IconLow } from "../../resources/svg/icon_home_platform_adv.svg";

const HomePlatformAdv = () => {
  const { t, i18n } = useTranslation();
  return (
    <div className="home-platform-advantage bg_image bg-style--29">
      <div className="pt--60 pb--50">
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="item">
                <div className="item-part-1">
                  <span className="item-text item-text--medium item-text--dark">
                    {t("home.platform-adv.text.1")}
                  </span>
                  <span className="item-text item-text--large item-text--red">
                    {t("home.platform-adv.text.2")}
                  </span>
                </div>
                <div className="item-part-2">
                  <span className="item-text item-text--small item-text--black">
                    {t("home.platform-adv.text.3")}
                  </span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="item">
                <div className="item-part-1">
                  <span className="item-text item-text--large item-text--red">
                    {t("home.platform-adv.text.4")}
                  </span>
                  <span className="item-text item-text--medium item-text--dark align-self--center">
                    {t("home.platform-adv.text.5")}
                  </span>
                  <span className="item-text item-text--large item-text--red">
                    {t("home.platform-adv.text.6")}
                  </span>
                </div>
                <div className="item-part-2">
                  <span className="item-text item-text--small item-text--black">
                    {t("home.platform-adv.text.7")}
                  </span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="item">
                <div className="item-part-1">
                  <span className="item-text item-text--medium item-text--dark">
                    {t("home.platform-adv.text.8")}
                  </span>
                  <span className="item-text item-text--large item-text--red">
                    {t("home.platform-adv.text.9")}
                  </span>
                </div>
                <div className="item-part-2">
                  <span className="item-text item-text--small item-text--black">
                    {t("home.platform-adv.text.10")}
                  </span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="item">
                <div className="item-part-1">
                  <span className="item-text item-text--large item-text--red">
                    {t("home.platform-adv.text.11")}
                  </span>
                  <span className="item-text item-text--medium item-text--dark align-self--center">
                    {t("home.platform-adv.text.12")}
                  </span>
                  <span className="item-text item-text--large item-text--red">
                    {t("home.platform-adv.text.13")}
                  </span>
                </div>
                <div className="item-part-2">
                  <span className="item-text item-text--small item-text--black">
                    {t("home.platform-adv.text.14")}
                  </span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="item">
                <div className="item-part-1">
                  <IconLow />
                </div>
                <div className="item-part-2">
                  <span className="item-text item-text--small item-text--black">
                    {t("home.platform-adv.text.15")}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePlatformAdv;
