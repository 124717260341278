import { useTranslation } from "react-i18next";
import classnames from "classnames";
import { ReactNode, useEffect, useState } from "react";
import { ReactComponent as Icon01 } from "../../resources/svg/icon_margin_01.svg";
import { ReactComponent as Icon02 } from "../../resources/svg/icon_margin_02.svg";
import { ReactComponent as Icon03 } from "../../resources/svg/icon_margin_03.svg";
import { ReactComponent as Icon04 } from "../../resources/svg/icon_margin_04.svg";
import { ReactComponent as Icon05 } from "../../resources/svg/icon_margin_05.svg";
import { ReactComponent as Icon06 } from "../../resources/svg/icon_margin_06.svg";
import { ReactComponent as Icon07 } from "../../resources/svg/icon_margin_07.svg";
import { ReactComponent as Icon08 } from "../../resources/svg/icon_margin_08.svg";

import img01 from "../../resources/corporate/hsbc.png";
import img02 from "../../resources/corporate/boc.png";

import ActionButton, {
  ActionButtonA,
} from "../../components/partial/ActionButton";
import { Link } from "react-router-dom";
import { Icon } from "@ailibs/feather-react-ts";
import SectionTitle from "../../components/partial/SectionTitle";

const MarginInOutTabsContents = (props: { tabNumber: number }) => {
  const { t } = useTranslation();
  const { tabNumber } = props;
  const NoOfCategory = tabNumber === 1 ? 5 : 1;
  const categories = Array(NoOfCategory)
    .fill("")
    .map((_, i) => ({
      title: t(`margin_inout.nav-tabs.nav.${tabNumber}.category.${i + 1}`),
      name: `tab${tabNumber}.cat${i + 1}`,
    }));

  const IconCircle = <Icon name={"circle"} color="#4063AA" size={18} />;
  const IconX = <Icon name={"x"} color="#E30E18" size={18} />;

  const data = [
    // 1st tab
    [
      {
        content: (
          <div className="content-grid">
            <div className="icon-container">
              <Icon01 />
            </div>
            <div className="content-container">
              <h3>{t("margin_inout.nav-tabs.nav.1.content.1")}</h3>
              <p>{t("margin_inout.nav-tabs.nav.1.content.2")}</p>
              <p>{t("margin_inout.nav-tabs.nav.1.content.58")}</p>
            </div>
          </div>
        ),
        category: `tab1.cat1`,
      },
      {
        content: (
          <div className="content-grid">
            <div className="icon-container">
              <Icon02 />
            </div>
            <div className="content-container">
              <h3>{t("margin_inout.nav-tabs.nav.1.content.3")}</h3>
              <ul className="mb--50">
                <li>{t("margin_inout.nav-tabs.nav.1.content.4")}</li>
                <li>{t("margin_inout.nav-tabs.nav.1.content.5")}</li>
                <li>{t("margin_inout.nav-tabs.nav.1.content.6")}</li>
              </ul>
              <ActionButtonA
                className="light"
                href={"/assets/download/Sample_WireTransfer.pdf"}
              >
                {t("margin_inout.nav-tabs.nav.1.content.7")}
              </ActionButtonA>
            </div>
          </div>
        ),
        category: `tab1.cat2`,
      },
      {
        content: (
          <div className="content-grid">
            <div className="icon-container">
              <Icon03 />
            </div>
            <div className="content-container">
              <h3>{t("margin_inout.nav-tabs.nav.1.content.8")}</h3>
              <p>{t("margin_inout.nav-tabs.nav.1.content.9")}</p>
              <p>{t("margin_inout.nav-tabs.nav.1.content.10")}</p>
            </div>
            <table className="table mt--50">
              <thead>
                <tr className="">
                  <th>{t("margin_inout.nav-tabs.nav.1.content.11")}</th>
                  <th>{t("margin_inout.nav-tabs.nav.1.content.12")}</th>
                  <th>{t("margin_inout.nav-tabs.nav.1.content.13")}</th>
                  <th>{t("margin_inout.nav-tabs.nav.1.content.14")}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <img src={img01} alt="..." />
                    <p>{t("margin_inout.nav-tabs.nav.1.content.15")}</p>
                    <p>
                      {t("margin_inout.nav-tabs.nav.1.content.16")}
                      <br />
                      {t("margin_inout.nav-tabs.nav.1.content.17")}
                    </p>
                    <p>
                      {t("margin_inout.nav-tabs.nav.1.content.18")}
                      <br />
                      {t("margin_inout.nav-tabs.nav.1.content.19")}
                    </p>
                  </td>
                  <td>{t("margin_inout.nav-tabs.nav.1.content.20")}</td>
                  <td>{t("margin_inout.nav-tabs.nav.1.content.21")}</td>
                  <td></td>
                </tr>
                <tr>
                  <td>
                    <img src={img02} alt="..." />
                    <p>
                      {t("margin_inout.nav-tabs.nav.1.content.22")}
                      <br />
                      {t("margin_inout.nav-tabs.nav.1.content.23")}
                    </p>
                    <p>
                      {t("margin_inout.nav-tabs.nav.1.content.24")}
                      <br />
                      {t("margin_inout.nav-tabs.nav.1.content.25")}
                    </p>
                    <p>
                      {t("margin_inout.nav-tabs.nav.1.content.26")}
                      <br />
                      {t("margin_inout.nav-tabs.nav.1.content.27")}
                    </p>
                  </td>
                  <td>{t("margin_inout.nav-tabs.nav.1.content.28")}</td>
                  <td>{t("margin_inout.nav-tabs.nav.1.content.29")}</td>
                  <td>{t("margin_inout.nav-tabs.nav.1.content.30")}</td>
                </tr>
              </tbody>
            </table>
          </div>
        ),
        category: `tab1.cat3`,
      },
      {
        content: (
          <div className="content-grid">
            <div className="icon-container">
              <Icon04 />
            </div>
            <div className="content-container">
              <h3>{t("margin_inout.nav-tabs.nav.1.content.31")}</h3>
              <p>{t("margin_inout.nav-tabs.nav.1.content.32")}</p>
              <p>{t("margin_inout.nav-tabs.nav.1.content.33")}</p>
            </div>
            <table className="table mt--55">
              <thead>
                <tr className="">
                  <th style={{ width: "30%" }}>
                    {t("margin_inout.nav-tabs.nav.1.content.34")}
                  </th>
                  <th>{t("margin_inout.nav-tabs.nav.1.content.35")}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>{t("margin_inout.nav-tabs.nav.1.content.36")}</th>
                  <td>
                    <p>{t("margin_inout.nav-tabs.nav.1.content.37")}</p>
                    <p>{t("margin_inout.nav-tabs.nav.1.content.38")}</p>
                  </td>
                </tr>
                <tr>
                  <th>{t("margin_inout.nav-tabs.nav.1.content.39")}</th>
                  <td>{t("margin_inout.nav-tabs.nav.1.content.40")}</td>
                </tr>
                <tr>
                  <th>{t("margin_inout.nav-tabs.nav.1.content.41")}</th>
                  <td>{t("margin_inout.nav-tabs.nav.1.content.42")}</td>
                </tr>
                <tr>
                  <th>{t("margin_inout.nav-tabs.nav.1.content.43")}</th>
                  <td>{t("margin_inout.nav-tabs.nav.1.content.44")}</td>
                </tr>
              </tbody>
            </table>
          </div>
        ),
        category: `tab1.cat4`,
      },
      {
        content: (
          <div className="content-grid">
            <div className="icon-container">
              <Icon05 />
            </div>
            <div className="content-container">
              <h3>{t("margin_inout.nav-tabs.nav.1.content.45")}</h3>
              <p>{t("margin_inout.nav-tabs.nav.1.content.46")}</p>
              <h5 className="theme-color">
                {t("margin_inout.nav-tabs.nav.1.content.47")}
              </h5>
              <ul>
                <li>{t("margin_inout.nav-tabs.nav.1.content.48")}</li>
                <li>{t("margin_inout.nav-tabs.nav.1.content.49")}</li>
                <li>{t("margin_inout.nav-tabs.nav.1.content.50")}</li>
                <li>{t("margin_inout.nav-tabs.nav.1.content.51")}</li>
                <li>{t("margin_inout.nav-tabs.nav.1.content.52")}</li>
              </ul>
            </div>
            <table className="table mt--50">
              <thead>
                <tr className="">
                  <th scope="col" rowSpan={2}>
                    {t("margin_inout.nav-tabs.nav.1.content.53")}
                  </th>
                  <th scope="col">
                    {t("margin_inout.nav-tabs.nav.1.content.54")}
                  </th>
                  <th scope="col"></th>
                  <th scope="col" rowSpan={2} className="line-col">
                    {t("margin_inout.nav-tabs.nav.1.content.57")}
                  </th>
                </tr>
                <tr className="">
                  <th>{t("margin_inout.nav-tabs.nav.1.content.55")}</th>
                  <th>{t("margin_inout.nav-tabs.nav.1.content.56")}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{t("margin_inout.nav-tabs.nav.1.content.bank.1")}</td>
                  <td>{IconCircle}</td>
                  <td>{IconCircle}</td>
                  <td>
                    <Link
                      className="theme-color"
                      target="_blank"
                      to={`https://${t(
                        "margin_inout.nav-tabs.nav.1.content.link.1"
                      )}`}
                    >
                      {t("margin_inout.nav-tabs.nav.1.content.link.1")}
                    </Link>
                  </td>
                </tr>
                <tr>
                  <td>{t("margin_inout.nav-tabs.nav.1.content.bank.2")}</td>
                  <td>{IconX}</td>
                  <td>{IconCircle}</td>
                  <td>
                    <Link
                      className="theme-color"
                      target="_blank"
                      to={`https://${t(
                        "margin_inout.nav-tabs.nav.1.content.link.2"
                      )}`}
                    >
                      {t("margin_inout.nav-tabs.nav.1.content.link.2")}
                    </Link>
                  </td>
                </tr>
                <tr>
                  <td>{t("margin_inout.nav-tabs.nav.1.content.bank.3")}</td>
                  <td>{IconCircle}</td>
                  <td>{IconCircle}</td>
                  <td>
                    <Link
                      className="theme-color"
                      target="_blank"
                      to={`https://${t(
                        "margin_inout.nav-tabs.nav.1.content.link.3"
                      )}`}
                    >
                      {t("margin_inout.nav-tabs.nav.1.content.link.3")}
                    </Link>
                  </td>
                </tr>
                <tr>
                  <td>{t("margin_inout.nav-tabs.nav.1.content.bank.4")}</td>
                  <td>{IconCircle}</td>
                  <td>{IconCircle}</td>
                  <td>
                    <Link
                      className="theme-color"
                      target="_blank"
                      to={`https://${t(
                        "margin_inout.nav-tabs.nav.1.content.link.4"
                      )}`}
                    >
                      {t("margin_inout.nav-tabs.nav.1.content.link.4")}
                    </Link>
                  </td>
                </tr>
                <tr>
                  <td>{t("margin_inout.nav-tabs.nav.1.content.bank.5")}</td>
                  <td>{IconCircle}</td>
                  <td>{IconCircle}</td>
                  <td>
                    <Link
                      className="theme-color"
                      target="_blank"
                      to={`https://${t(
                        "margin_inout.nav-tabs.nav.1.content.link.5"
                      )}`}
                    >
                      {t("margin_inout.nav-tabs.nav.1.content.link.5")}
                    </Link>
                  </td>
                </tr>
                <tr>
                  <td>{t("margin_inout.nav-tabs.nav.1.content.bank.6")}</td>
                  <td>{IconCircle}</td>
                  <td>{IconX}</td>
                  <td>
                    <Link
                      className="theme-color"
                      target="_blank"
                      to={`https://${t(
                        "margin_inout.nav-tabs.nav.1.content.link.6"
                      )}`}
                    >
                      {t("margin_inout.nav-tabs.nav.1.content.link.6")}
                    </Link>
                  </td>
                </tr>
                <tr>
                  <td>{t("margin_inout.nav-tabs.nav.1.content.bank.7")}</td>
                  <td>{IconCircle}</td>
                  <td>{IconCircle}</td>
                  <td>
                    <Link
                      className="theme-color"
                      target="_blank"
                      to={`https://${t(
                        "margin_inout.nav-tabs.nav.1.content.link.7"
                      )}`}
                    >
                      {t("margin_inout.nav-tabs.nav.1.content.link.7")}
                    </Link>
                  </td>
                </tr>
                <tr>
                  <td>{t("margin_inout.nav-tabs.nav.1.content.bank.8")}</td>
                  <td>{IconCircle}</td>
                  <td>{IconCircle}</td>
                  <td>
                    <Link
                      className="theme-color"
                      target="_blank"
                      to={`https://${t(
                        "margin_inout.nav-tabs.nav.1.content.link.8"
                      )}`}
                    >
                      {t("margin_inout.nav-tabs.nav.1.content.link.8")}
                    </Link>
                  </td>
                </tr>
                <tr>
                  <td>{t("margin_inout.nav-tabs.nav.1.content.bank.9")}</td>
                  <td>{IconCircle}</td>
                  <td>{IconCircle}</td>
                  <td>
                    <Link
                      className="theme-color"
                      target="_blank"
                      to={`https://${t(
                        "margin_inout.nav-tabs.nav.1.content.link.9"
                      )}`}
                    >
                      {t("margin_inout.nav-tabs.nav.1.content.link.9")}
                    </Link>
                  </td>
                </tr>
                <tr>
                  <td>{t("margin_inout.nav-tabs.nav.1.content.bank.10")}</td>
                  <td>{IconCircle}</td>
                  <td>{IconCircle}</td>
                  <td>
                    <Link
                      className="theme-color"
                      target="_blank"
                      to={`https://${t(
                        "margin_inout.nav-tabs.nav.1.content.link.10"
                      )}`}
                    >
                      {t("margin_inout.nav-tabs.nav.1.content.link.10")}
                    </Link>
                  </td>
                </tr>
                <tr>
                  <td>{t("margin_inout.nav-tabs.nav.1.content.bank.11")}</td>
                  <td>{IconCircle}</td>
                  <td>{IconCircle}</td>
                  <td>
                    <Link
                      className="theme-color"
                      target="_blank"
                      to={`https://${t(
                        "margin_inout.nav-tabs.nav.1.content.link.11"
                      )}`}
                    >
                      {t("margin_inout.nav-tabs.nav.1.content.link.11")}
                    </Link>
                  </td>
                </tr>
                <tr>
                  <td>{t("margin_inout.nav-tabs.nav.1.content.bank.12")}</td>
                  <td>{IconCircle}</td>
                  <td>{IconCircle}</td>
                  <td>
                    <Link
                      className="theme-color"
                      target="_blank"
                      to={`https://${t(
                        "margin_inout.nav-tabs.nav.1.content.link.12"
                      )}`}
                    >
                      {t("margin_inout.nav-tabs.nav.1.content.link.12")}
                    </Link>
                  </td>
                </tr>
                <tr>
                  <td>{t("margin_inout.nav-tabs.nav.1.content.bank.13")}</td>
                  <td>{IconCircle}</td>
                  <td>{IconX}</td>
                  <td>
                    <Link
                      className="theme-color"
                      target="_blank"
                      to={`https://${t(
                        "margin_inout.nav-tabs.nav.1.content.link.13"
                      )}`}
                    >
                      {t("margin_inout.nav-tabs.nav.1.content.link.13")}
                    </Link>
                  </td>
                </tr>
                <tr>
                  <td>{t("margin_inout.nav-tabs.nav.1.content.bank.14")}</td>
                  <td>{IconX}</td>
                  <td>{IconCircle}</td>
                  <td>
                    <Link
                      className="theme-color"
                      target="_blank"
                      to={`https://${t(
                        "margin_inout.nav-tabs.nav.1.content.link.14"
                      )}`}
                    >
                      {t("margin_inout.nav-tabs.nav.1.content.link.14")}
                    </Link>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        ),
        category: `tab1.cat5`,
      },
    ],
    // 2nd tab
    [
      {
        content: (
          <div className="content-grid">
            <div className="icon-container">
              <Icon06 />
            </div>
            <div className="content-container">
              <h3>{t("margin_inout.nav-tabs.nav.2.content.1")}</h3>
              <p>{t("margin_inout.nav-tabs.nav.2.content.2")}</p>
              <h5 className="theme-color mt--30">
                {t("margin_inout.nav-tabs.nav.2.content.3")}
              </h5>
              <ul>
                <li>{t("margin_inout.nav-tabs.nav.2.content.4")}</li>
                <li>{t("margin_inout.nav-tabs.nav.2.content.5")}</li>
                <li>{t("margin_inout.nav-tabs.nav.2.content.6")}</li>
                <li>{t("margin_inout.nav-tabs.nav.2.content.7")}</li>
                <li>{t("margin_inout.nav-tabs.nav.2.content.8")}</li>
                <li>{t("margin_inout.nav-tabs.nav.2.content.22")}</li>
                <li>{t("margin_inout.nav-tabs.nav.2.content.23")}</li>
                <li>{t("margin_inout.nav-tabs.nav.2.content.24")}</li>
              </ul>
            </div>
          </div>
        ),
        category: `tab2.cat1`,
      },
      {
        content: (
          <div className="content-grid">
            <div className="icon-container">
              <Icon07 />
            </div>
            <div className="content-container">
              <h3>{t("margin_inout.nav-tabs.nav.2.content.9")}</h3>
              <ul>
                <li>{t("margin_inout.nav-tabs.nav.2.content.10")}</li>
                <li>{t("margin_inout.nav-tabs.nav.2.content.11")}</li>
                <li>{t("margin_inout.nav-tabs.nav.2.content.12")}</li>
              </ul>
              <h5 className="theme-color mt--30">
                {t("margin_inout.nav-tabs.nav.2.content.13")}
              </h5>
              <ul>
                <li>{t("margin_inout.nav-tabs.nav.2.content.14")}</li>
                <li>{t("margin_inout.nav-tabs.nav.2.content.15")}</li>
              </ul>
            </div>
          </div>
        ),
        category: `tab2.cat2`,
      },
      {
        content: (
          <div className="content-grid">
            <div className="icon-container">
              <Icon08 />
            </div>
            <div className="content-container">
              <h3>{t("margin_inout.nav-tabs.nav.2.content.16")}</h3>
              <ul>
                <li>{t("margin_inout.nav-tabs.nav.2.content.17")}</li>
                <li>{t("margin_inout.nav-tabs.nav.2.content.18")}</li>
                <li>{t("margin_inout.nav-tabs.nav.2.content.19")}</li>
              </ul>
              <h5 className="theme-color mt--30">
                {t("margin_inout.nav-tabs.nav.2.content.20")}
              </h5>
              <ul>
                <li>{t("margin_inout.nav-tabs.nav.2.content.21")}</li>
              </ul>
            </div>
          </div>
        ),
        category: `tab2.cat3`,
      },
    ],
  ];

  // the state
  const [activeCategory, setActiveCategory] = useState(categories.at(0)?.name);
  const [filteredData, setFilteredData] = useState<ReactNode>();
  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    const d = data
      .at(tabNumber - 1)
      ?.find((item) => item.category === activeCategory);
    setFilteredData(d?.content);
  }, [activeCategory]);

  return (
    <div className="margin-in-out-inner-content-container">
      <div className="">
        {/* filter row */}
        <div className="row mt--45 mt_sm--0 mt_md--25 mb_sm--60 mb_md--60">
          <div className="col-12">
            <ul className="liststyle d-flex tag-navs">
              {categories.map((c, i) => {
                return (
                  <li
                    className={classnames({
                      active: c.name === activeCategory,
                      open: menuOpen,
                    })}
                    key={i}
                  >
                    <SectionTitle
                      onClick={() => {
                        setActiveCategory(c.name);
                        setMenuOpen(!menuOpen);
                      }}
                      title={c.title}
                    />
                  </li>
                );
              })}
            </ul>
          </div>
        </div>

        {/* content */}
        <div className="row justify-content-center mt_lg--50 mt_md--50 mt_sm--0 mt--150 mb--50 gx-5 ">
          <div className="col-12">
            <div className="container-sm filtered-data-wrapper">
              {filteredData}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MarginInOutTabsContents;
