import { useTranslation } from "react-i18next";
import PageHelment from "../components/common/Helment";
import Breadcrumb from "../components/header/Breadcrumb";
import CallAction from "../components/partial/CallAction";
import banner26 from "../resources/banner/banner26.jpg";
import tradingCentralLogo from "../resources/platforms/trading_central.png";
import IntroDark from "../blocks/intro/IntroDark";
import TradingCentralSection1 from "../blocks/platform/TradingCentralSection1";
import TradingCentralSection2 from "../blocks/platform/TradingCentralSection2";

const PlatformTradingCentral = () => {
  const { t } = useTranslation();
  return (
    <>
      <PageHelment
        title={`${t("common.meta.prefix")} | ${t(
          "trading_central.meta.title"
        )} | ${t("common.meta.suffix")}`}
        description={t("trading_central.meta.description")}
      />
      <CallAction
        bgImage={<img src={banner26} alt={t("trading_central.meta.title")} />}
        className="ptb--100"
        textAlign="left"
        tagline={t("trading_central.banner.tagline")}
        h1={t("trading_central.banner.title")}
        description={t("trading_central.banner.description")}
      />
      <Breadcrumb
        parentPage={t("trading_central.breadcrumb.level.1")}
        currentPage={t("trading_central.breadcrumb.level.2")}
      />
      <IntroDark
        title={t("trading_central.intro.header")}
        description={t("trading_central.intro.description")}
        image={
          <img
            src={tradingCentralLogo}
            alt={t("trading_central.meta.title")}
            style={{ maxHeight: "150px" }}
          />
        }
      />
      <TradingCentralSection1 />
      <TradingCentralSection2 />
    </>
  );
};

export default PlatformTradingCentral;
