import { useTranslation } from "react-i18next";
import { NavTab } from "../../components/partial/NavTabs.type";
import NavTabs from "../../components/partial/NavTabs";
import MarginInOutTabsContents from "./MarginInOutTabsContents";

const MarginInOutTabs = () => {
  const { t } = useTranslation();

  const data: NavTab[] = [
    {
      nav: t("margin_inout.nav-tabs.nav.1"),
      content: <MarginInOutTabsContents tabNumber={1} />,
    },
    {
      nav: t("margin_inout.nav-tabs.nav.2"),
      content: <MarginInOutTabsContents tabNumber={2} />,
    },
  ];

  return (
    <div className="margin-in-out-content">
      <NavTabs data={data} />
    </div>
  );
};

export default MarginInOutTabs;
