import { useTranslation } from "react-i18next";
import PageHelment from "../components/common/Helment";
import HomeSlider from "../blocks/slider/HomeSlider";
import HomeIntro from "../blocks/home/HomeIntro";
import CallAction from "../components/partial/CallAction";
import bg02 from "../resources/background/bg02.jpg";
import HomeInfo from "../blocks/home/HomeInfo";
import HomeAwards from "../blocks/home/HomeAwards";
import NotificationBar from "../components/header/NotificationBar";
import HomePlatformAdv from "../blocks/home/HomePlatformAdv";

const Home = () => {
  const { t } = useTranslation();
  return (
    <>
      <PageHelment
        title={`${t("home.meta.title")} | ${t("common.meta.suffix")}`}
        description={t("home.meta.description")}
      />
      <NotificationBar />
      <HomeSlider />
      <HomePlatformAdv />
      <HomeIntro />
      <CallAction
        style={{
          backgroundImage: `url(${bg02})`,
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "fixed",
        }}
        className="ptb--300 d-none d-sm-flex"
      />
      <HomeInfo />
      <HomeAwards />
    </>
  );
};

export default Home;
