import { useTranslation } from "react-i18next";
import bg14 from "../resources/background/bg14.png";
import PageHelment from "../components/common/Helment";
import Breadcrumb from "../components/header/Breadcrumb";
import CallAction from "../components/partial/CallAction";
import AnnouncementDetail from "../blocks/announcement/AnnouncementDetail";
import useFetch from "use-http";
import { useLocation, useParams } from "react-router-dom";
import { useEffect, useState } from "react";

const AnnouncementDetails = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const params = useParams();
  const id = params.id;

  const [announcementData, setAnnouncementData] = useState<any>({
    announcement: 0,
  });

  const { get, post, response, loading, error } = useFetch(
    process.env.REACT_APP_BACKEND_API_URL
  );

  // get single announcement function
  const getAnnouncement = async () => {
    const results = await get(`/announcement/${id}`);
    if (response.ok) setAnnouncementData(results);
  };

  useEffect(() => {
    getAnnouncement();
  }, [pathname]);

  return (
    <>
      <PageHelment
        title={`${t("common.meta.prefix")} | ${
          announcementData.announcement.title
        }`}
        description={t("announcement.meta.description")}
      />
      <CallAction
        bgImage={<img src={bg14} alt={t("announcement.meta.title")} />}
        className="ptb--100"
        textAlign="left"
        tagline={t("announcement.banner.tagline")}
        h1={t("announcement.banner.title")}
        description={t("announcement.banner.description")}
      />
      <Breadcrumb
        parentPage={t("announcement.breadcrumb.level.1")}
        currentPage={announcementData.announcement.title}
      />
      <AnnouncementDetail />
    </>
  );
};

export default AnnouncementDetails;
