import { useTranslation } from "react-i18next";
import { ReactComponent as Logo } from "../../resources/svg/logo_white.svg";
import LanguageSwitcher from "../header/LanguageSwitcher";
import { ReactComponent as IconSectionTitle } from "../../resources/svg/icon_section_title.svg";
import { FOOTER_MENU, FOOTER_NAV } from "../../constants";
import { buildUrl } from "../../helpers";
import { Link } from "react-router-dom";
import LanguageSwitcherSimple from "../header/LanguageSwitcherSimple";

const Footer = () => {
  const { t, i18n } = useTranslation();

  return (
    <footer className="footer-area ptb--80 ptb_sm--40">
      <div className="footer-wrapper poss_relative">
        <div className="container-sm">
          {/* row 1 */}
          <div className="row mb--100 mb_sm--10">
            <div className="col-6 col-sm">
              <div className="logo">
                <Link to={`/${i18n.language}`}>
                  <Logo />
                </Link>
              </div>
            </div>
            <div className="col-6 col-sm-auto d-flex align-items-stretch justify-content-end">
              <div className="d-none d-sm-block">
                {/* desktop */}
                <LanguageSwitcher />
              </div>
              <div className="d-none d-sm-none align-items-center">
                {/* mobile */}
                <LanguageSwitcherSimple />
              </div>
            </div>
          </div>

          <div className="footer-divider mt--40 mb--40 mb_md--15"></div>

          {/* row 2 */}
          <div className="row gx-0 gx-sm-5 gx-md-4 mb--100 mb_sm--50 mb_md--50">
            {FOOTER_MENU.map((lvl1, i) => (
              <div className="col-4 col-sm-4 col-md footer-menu" key={i}>
                <h4 className="footer-menu-title">
                  <IconSectionTitle width={16} height={20} />
                  <span>{t(lvl1.label)}</span>
                </h4>
                <ul className="footer-menu-link liststyle">
                  {lvl1.sub.map((lvl2, j) => (
                    <li key={j}>
                      <Link
                        to={buildUrl(lvl2.href, i18n.language)}
                        target={lvl2.target}
                      >
                        {t(lvl2.label)}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>

          <div className="footer-divider mt--40 mb--30 mb_sm--15 mt_sm--15"></div>

          {/* row 3 */}
          <div className="footer-nav-wrapper mb--50">
            <ul className="footer-nav liststyle">
              {FOOTER_NAV.map((link, i) => (
                <li key={i}>
                  <Link
                    to={buildUrl(link.href, i18n.language)}
                    target={link.target}
                  >
                    {t(link.label)}
                  </Link>
                </li>
              ))}
            </ul>
          </div>

          <div className="row">
            <div className="col-lg-12">
              <div className="copyright-text">
                <div className="small">{t("footer.copyright.line1")}</div>
                <div>
                  {t("footer.copyright.line2", {
                    year: new Date().getFullYear(),
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
