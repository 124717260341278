import { Trans, useTranslation } from "react-i18next";
import SectionTitle from "../../components/partial/SectionTitle";
import mt4Screens from "../../resources/platforms/mt4_screen.png";

const MT4Content = () => {
  const { t, i18n } = useTranslation();
  return (
    <div className="mt4-content bg-style--3_ bg_image">
      <div className="pt--150 pb--85 pt_md--60 pt_md--75 pt_sm--60 pt_sm--75">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-6 d-md-none text-center">
              <img
                className="mb--60"
                src={mt4Screens}
                alt={t("mt4.content.header")}
              />
            </div>
            <div className="col-12 col-md-7">
              <SectionTitle title={t("mt4.content.header")} />
              <div className="mt--60 mt_md--25 text-center text-md-start">
                <p>{t("mt4.content.text.1")} </p>
                <p>{t("mt4.content.text.2")} </p>
                <p>{t("mt4.content.text.3")} </p>
              </div>

              <div className="steps-container mt--40 mt_sm--50 pb--160 pb_md--0 pb_sm--0">
                <div className="d-grid">
                  <div>
                    <div className="blue-round">1</div>
                  </div>
                  <div>
                    <Trans
                      i18nKey="mt4.content.text.4"
                      components={{
                        italic: <i />,
                        bold: <strong />,
                        red: <span className="theme-color" />,
                      }}
                    />
                  </div>
                  {/*  */}
                  <div>
                    <div className="blue-round">2</div>
                  </div>
                  <div>
                    <Trans
                      i18nKey="mt4.content.text.5"
                      components={{
                        italic: <i />,
                        bold: <strong />,
                        red: <span className="theme-color" />,
                      }}
                    />
                  </div>
                  {/*  */}
                  <div>
                    <div className="blue-round">3</div>
                  </div>
                  <div>
                    <Trans
                      i18nKey="mt4.content.text.6"
                      components={{
                        italic: <i />,
                        bold: <strong />,
                        red: <span className="theme-color" />,
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-5">
              <img
                className="img-screen-50"
                src={mt4Screens}
                alt={t("mt4.content.header")}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MT4Content;
