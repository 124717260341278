import { useTranslation } from "react-i18next";
import { Item } from "../../components/partial/ListPresentType";
import { ReactComponent as Icon01 } from "../../resources/svg/icon_adv_01.svg";
import { ReactComponent as Icon02 } from "../../resources/svg/icon_adv_02.svg";
import { ReactComponent as Icon03 } from "../../resources/svg/icon_adv_03.svg";
import { ReactComponent as Icon04 } from "../../resources/svg/icon_adv_04.svg";
import { ReactComponent as Icon05 } from "../../resources/svg/icon_adv_05.svg";
import { ReactComponent as Icon06 } from "../../resources/svg/icon_adv_06.svg";

import adv01 from "../../resources/about-content/Hantec_graphic_01-06.png";
import adv02 from "../../resources/about-content/Hantec_graphic_01-07.png";
import adv03 from "../../resources/about-content/Hantec_graphic_01-08.png";
import adv04 from "../../resources/about-content/Hantec_graphic_01-09.png";
import adv05 from "../../resources/about-content/Hantec_graphic_01-10.png";
import adv06 from "../../resources/about-content/Hantec_graphic_01-11.png";

import SectionTitle from "../../components/partial/SectionTitle";
import ListLogo from "../../components/partial/ListLogo";

const AboutHblAdvantages = () => {
  const { t } = useTranslation();

  const data: Item[] = [
    {
      icon: <img src={adv01} alt={t("aboutHblAdv.content.section2.title.1")} />,
      title: t("aboutHblAdv.content.section2.title.1"),
      description: t("aboutHblAdv.content.section2.description.1"),
    },
    {
      icon: <img src={adv02} alt={t("aboutHblAdv.content.section2.title.2")} />,
      title: t("aboutHblAdv.content.section2.title.2"),
      description: t("aboutHblAdv.content.section2.description.2"),
    },
    {
      icon: <img src={adv03} alt={t("aboutHblAdv.content.section2.title.3")} />,
      title: t("aboutHblAdv.content.section2.title.3"),
      description: t("aboutHblAdv.content.section2.description.3"),
    },
    {
      icon: <img src={adv04} alt={t("aboutHblAdv.content.section2.title.4")} />,
      title: t("aboutHblAdv.content.section2.title.4"),
      description: t("aboutHblAdv.content.section2.description.4"),
    },
    {
      icon: <img src={adv05} alt={t("aboutHblAdv.content.section2.title.5")} />,
      title: t("aboutHblAdv.content.section2.title.5"),
      description: t("aboutHblAdv.content.section2.description.5"),
    },
    {
      icon: <img src={adv06} alt={t("aboutHblAdv.content.section2.title.6")} />,
      title: t("aboutHblAdv.content.section2.title.6"),
      description: t("aboutHblAdv.content.section2.description.6"),
    },
  ];

  return (
    <div className="about-hbl-advantages ptb--150 pt_sm--60 pb_sm--75 pt_md--60 pb_md--75 bg-style--8 bg_image">
      <div className="container">
        <div className="row justify-content-left">
          <div className="col-lg-12">
            <div className="text-left">
              <SectionTitle title={t("aboutHblAdv.content.section2.header")} />
            </div>
          </div>
        </div>
        <div className="row">
          <ListLogo col="col-xl-2 col-lg-4 col-md-4 col-xl-3" items={data} />
        </div>
      </div>
    </div>
  );
};
export default AboutHblAdvantages;
