import classNames from "classnames";
import SectionTitle from "../../components/partial/SectionTitle";

interface IntroDarkProps {
  title: string;
  description: string;
  image?: React.ReactNode;
  children?: React.ReactNode;
  className?: string;
  hasDownlod?: boolean;
}

const IntroDark = (props: IntroDarkProps) => {
  return (
    <div
      className={classNames("intro-dark", props.className, {
        "has-download": props.hasDownlod,
      })}
    >
      <div className="container pt--75 pb--150 pt_sm--60 pb_sm--75 pt_md--60 pb_md--75">
        <div className="row">
          <div className="col-12">
            <div className="mb--30 mb_sm--60 mb_md--60">
              <SectionTitle title={props.title} />
            </div>
          </div>
        </div>
        <div className="row gx-5">
          <div className="col-12 col-md-8">
            <div className="intro-dark-description">{props.description}</div>
          </div>
          {/* <div className="col"></div> */}
          {props.image && (
            <div className="col-12 col-md-4 mt_sm--50">
              <div className="intro-dark-image text-md-end">{props.image}</div>
            </div>
          )}
        </div>
        <div className="row">
          <div className="col">{props.children}</div>
        </div>
      </div>
    </div>
  );
};

export default IntroDark;
