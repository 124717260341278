import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import useFetch from "use-http";
import ActionButton from "../../components/partial/ActionButton";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Slider from "react-slick";
import { ReactComponent as IconShare } from "../../resources/svg/icon_share.svg";
import { ReactComponent as IconShareRed } from "../../resources/svg/icon_share_red.svg";
import { LANGUAGES } from "../../constants";
import { htmlDecode } from "../../helpers";

const AnnouncementDetail = () => {
  const { t, i18n } = useTranslation();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const params = useParams();
  const id = params.id;

  /**
   * announcement data
   */
  const [announcementData, setAnnouncementData] = useState<any>({
    announcement: 0,
  });
  const [announcementListData, setAnnouncementListData] = useState<any>({
    announcements: [],
  });
  const [currentIndex, setCurrentIndex] = useState(-99);
  const [images, setImages] = useState([]);
  const [date, setDate] = useState("");
  const [copySuccess, setCopySuccess] = useState(false);
  const { get, post, response, loading, error } = useFetch(
    process.env.REACT_APP_BACKEND_API_URL
  );

  // get single announcement function
  const getAnnouncement = async () => {
    const results = await get(`/announcement/${id}`);
    if (response.ok) setAnnouncementData(results);
  };

  // get announcement function
  const getAnnouncements = async () => {
    // get the language short code
    const languageCode =
      LANGUAGES.find((l) => l.locale === i18n.language)?.code || "tc";

    const results = await get(`/announcements/${languageCode}`);
    if (response.ok) setAnnouncementListData(results);
  };

  useEffect(() => {
    getAnnouncement();
    getAnnouncements();
  }, [pathname]);

  useEffect(() => {
    if (announcementData.announcement) {
      if (announcementData.announcement.images) {
        const json = announcementData.announcement.images;
        const obj = JSON.parse(json);
        setImages(obj);
      }

      if (announcementData.announcement.announcement_date) {
        const theDate = new Date(
          announcementData.announcement.announcement_date
        );
        const day = ("0" + theDate.getDate()).slice(-2);
        const month = ("0" + (theDate.getMonth() + 1)).slice(-2);
        const year = theDate.getFullYear();
        const dateString = `${day}/${month}/${year}`;
        setDate(dateString);
      }
    } else {
      if (announcementData.announcement === null) {
        navigate(`/${i18n.language}/announcement/`);
      }
    }
  }, [announcementData]);

  useEffect(() => {
    if (announcementListData.announcements) {
      const index = announcementListData.announcements.findIndex(
        (a: any) => a.id === id
      );
      setCurrentIndex(index);
    }
  }, [announcementListData]);

  useEffect(() => {
    if (error) {
      navigate(`/${i18n.language}/announcement/`);
    }
  }, [error]);

  // copy to clipboard
  const copyToClip = async () => {
    await navigator.clipboard.writeText(location.href);
    setCopySuccess(true);
    setTimeout(() => {
      setCopySuccess(false);
    }, 3000);
  };

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    className: "center",
  };

  return (
    <div className="announcement-wrapper ptb--145 ptb_sm--50 ptb_md--75 ptb_lg--75">
      <div className="container">
        <div className="content-wrapper d-flex d-sm-block">
          {announcementData.announcement && (
            <>
              <h2 className="mb--80 mb_sm--0">
                {announcementData.announcement.title}
              </h2>
              {images.length > 0 && (
                <Slider {...sliderSettings}>
                  {images.map((img: any, i: number) => (
                    <div className="slide-item" key={i}>
                      <div
                        className="bg-container"
                        style={{ backgroundImage: `url(${img.src})` }}
                      ></div>
                    </div>
                  ))}
                </Slider>
              )}
              <div className="announcement-meta mt--75 mb--80 mt_sm--30 mb_sm--30 mt_md--75 mb_md--50">
                <div className="announcement-date">{date}</div>
                <div className="announcement-share">
                  <span
                    className="announcement-share-button"
                    onClick={() => copyToClip()}
                  >
                    {copySuccess ? <IconShareRed /> : <IconShare />}
                  </span>
                  {copySuccess && (
                    <span className="announcement-share-button-result">
                      {t("common.button.link-copied")}
                    </span>
                  )}
                </div>
              </div>

              {/* the content */}
              <div className="announcement-content mt--80 mb--80 mt_sm--0 mb_sm--50 mt_md--50 mb_md--50 mt_lg--75 mb_lg--75">
                {announcementData.announcement.content && (
                  <div
                    className="html"
                    dangerouslySetInnerHTML={{
                      __html: htmlDecode(announcementData.announcement.content),
                    }}
                  />
                )}
              </div>

              {/* the footer */}
              <div className="announcement-footer mt--90 mb--75 mt_sm--0 mb_sm--0 mt_md--50 mb_md--25">
                <div className="announcement-footer-back">
                  <ActionButton
                    className="light"
                    to={`/${i18n.language}/announcement`}
                  >
                    {t("announcement.detail.footer.back")}
                  </ActionButton>
                </div>
                <div className="announcement-footer-next">
                  {announcementListData.announcements.at(currentIndex + 1) && (
                    <ActionButton
                      className="light"
                      to={`/${i18n.language}/announcement_details/${
                        announcementListData.announcements.at(currentIndex + 1)
                          .id
                      }`}
                    >
                      {t("announcement.detail.footer.next")}
                    </ActionButton>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default AnnouncementDetail;
