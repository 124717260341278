import { useTranslation } from "react-i18next";
import SectionTitle from "../../components/partial/SectionTitle";
import ActionButton from "../../components/partial/ActionButton";
import ListPresent from "../../components/partial/ListPresent";
import { ReactComponent as Icon01 } from "../../resources/svg/icon_list_01.svg";
import { ReactComponent as Icon02 } from "../../resources/svg/icon_list_02.svg";
import { ReactComponent as Icon03 } from "../../resources/svg/icon_list_03.svg";
import { ReactComponent as Icon04 } from "../../resources/svg/icon_list_04.svg";

const HomeIntro = () => {
  const { t, i18n } = useTranslation();
  const list = Array(4)
    .fill("")
    .map((e, i) => {
      let icon;
      switch (i + 1) {
        case 1:
          icon = <Icon01 />;
          break;
        case 2:
          icon = <Icon02 />;
          break;
        case 3:
          icon = <Icon03 />;
          break;
        case 4:
          icon = <Icon04 />;
          break;
        default:
          icon = <Icon01 />;
          break;
      }

      return {
        icon: icon,
        title: t(`service.list.title.${i + 1}`),
        description: t(`service.list.description.${i + 1}`),
      };
    });

  return (
    <div className="home-01-intro ptb--150 pt_sm--60 pt_md--60 pb_sm--75 pb_sm--75 bg-style--3_ bg_image">
      <div className="container">
        <div className="row justify-content-md-center">
          <div className="col-lg-8 col-md-10">
            <div className="text-center">
              <div className="mb--30 mb_sm--60 mb_md--60">
                <SectionTitle title={t("home.intro.header")} />
              </div>
              <p>{t("home.intro.description")}</p>
            </div>
          </div>
        </div>

        <div className="row justify-content-md-center">
          <div className="col-lg-12">
            <div className="mt--150 mt_sm--75 mt_md--60">
              <ListPresent items={list} col="col-12 col-sm-6 col-md-3" />
            </div>
          </div>
          <div className="col">
            <div className="text-center">
              <ActionButton
                className="mt--80 mt_sm--0 light"
                to={`/${i18n.language}/hbl_advantages`}
                id="advan_info_1"
              >
                {t("common.button.read-more")}
              </ActionButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeIntro;
