import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import Header from "../header/Header";
import Footer from "../footer/Footer";
import Page404 from "../../pages/404";
import ScrollToTop from "./ScrollToTop";
import "typeface-inter";
import "typeface-montserrat";
import "typeface-noto-sans-tc";
import "typeface-noto-sans-sc";

const App = () => {
  // Get i18n instance
  const { i18n } = useTranslation();
  // Get parameters
  const params = useParams();
  // Get current locale from url
  const currentLocale = params.locale!;
  // check language exist
  const validLocale = i18n.languages.includes(currentLocale);

  // Update i18n language on page load
  useEffect(() => {
    if (validLocale) {
      i18n.changeLanguage(currentLocale);
    }
  }, []);

  return (
    <>
      {validLocale ? (
        <div className="App">
          <Header />
          <Outlet />
          <Footer />
          {/* ScrollToTop component inside App component */}
          <ScrollToTop />
        </div>
      ) : (
        <div className="App">
          <Header />
          {/* <Page404 /> */}
          <Footer />
          {/* ScrollToTop component inside App component */}
          {/* <ScrollToTop /> */}
        </div>
      )}
    </>
  );
};

export default App;
