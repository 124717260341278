import { Trans, useTranslation } from "react-i18next";
import SectionTitle from "../../components/partial/SectionTitle";
import { ReactComponent as Icon01 } from "../../resources/svg/icon_contact_01.svg";
import { ReactComponent as Icon02 } from "../../resources/svg/icon_contact_02.svg";
import { ReactComponent as Icon03 } from "../../resources/svg/icon_contact_03.svg";
import { ReactComponent as Icon04 } from "../../resources/svg/icon_contact_04.svg";
import qrImage from "../../resources/logo/qr_code.png";
import ActionButton from "../../components/partial/ActionButton";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const ContactInfo = () => {
  const { t, i18n } = useTranslation();
  const [showPopup, setShowPopup] = useState(false);
  useEffect(() => {
    if (showPopup) {
      document.documentElement.style.overflow = "hidden";
    } else {
      document.documentElement.style.overflow = "auto";
    }
  }, [showPopup]);

  const QRImage = () => {
    return (
      <div className="popup">
        <div className="popup-overlay"></div>
        <div className="popup-container">
          <div className="popup-wrapper bg-transparent">
            <img src={qrImage} alt={t("contact.info.data.7")} />
            <Link
              className="close-button"
              to={``}
              onClick={() => setShowPopup(false)}
            >
              <div className="close-icon"></div>
            </Link>
          </div>
        </div>
      </div>
    );
  };

  const items = [
    {
      icon: <Icon01 />,
      content: (
        <>
          <h3 className="title mb--25 mb_sm--5 mb_md--10">
            {t("contact.info.data.1")}
          </h3>
          <p className="">
            <Trans
              i18nKey="contact.info.data.2"
              components={{
                italic: <i />,
                bold: <strong />,
                red: <span className="theme-color" />,
                atag: <a />,
              }}
            />
            <br />
            {t("contact.info.data.3")}
          </p>
        </>
      ),
    },
    {
      icon: <Icon02 />,
      content: (
        <>
          <h3 className="title mb--25 mb_sm--5 mb_md--10">
            {t("contact.info.data.4")}
          </h3>
          <p className="">
            <a
              className="text-style"
              href={`mailto:${t("contact.info.data.5")}`}
            >
              {t("contact.info.data.5")}
            </a>
            <br />
            <a
              className="text-style"
              href={`mailto:${t("contact.info.data.6")}`}
            >
              {t("contact.info.data.6")}
            </a>
          </p>
        </>
      ),
    },
    {
      icon: <Icon03 />,
      content: (
        <>
          <h3 className="title mb--25 mb_sm--5 mb_md--10">
            {t("contact.info.data.7")}
          </h3>
          <p className="">
            <ActionButton
              className="light"
              to=""
              onClick={() => setShowPopup(true)}
            >
              {t("contact.info.data.8")}
            </ActionButton>
          </p>
          {showPopup && <QRImage />}
        </>
      ),
    },
    // {
    //   icon: <Icon04 />,
    //   content: (
    //     <>
    //       <h3 className="title mb--25 mb_sm--5 mb_md--10">
    //         {t("contact.info.data.9")}
    //       </h3>
    //       <p className="">
    //         {t("contact.info.data.10")}
    //         <br />
    //         {t("contact.info.data.11")}
    //       </p>
    //     </>
    //   ),
    // },
  ];

  return (
    <div className="contact-info bg-style--3_ bg_image">
      <div className="pt--150 pb--200 pt_sm--60 pb_sm--75 pt_md--60 pb_md--75">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="mb--160 mb_sm--60 mb_md--60">
                <SectionTitle title={t("contact.info.header")} />
              </div>
            </div>
            {/*  */}
            <div className="col-12">
              <div className="list-present">
                <div className="row justify-content-center gy-3">
                  {items.map((item, i) => (
                    <div
                      className={`col-12 col-sm-6 col-md-4 col-lg-3`}
                      key={i}
                    >
                      <div className="content-wrapper d-flex d-sm-block d-md-block h-100">
                        {item.icon && (
                          <div className="icon mb--30 mb_sm--0 mb_md--0">
                            {item.icon}
                          </div>
                        )}
                        <div className="content">{item.content}</div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactInfo;
