import { Trans } from "react-i18next";

const TransWithComponent = ({ tranKey }: { tranKey: string }) => {
  return (
    <>
      <Trans
        i18nKey={tranKey}
        components={{
          italic: <i />,
          bold: <strong />,
          red: <span className="theme-color" />,
          atag: <a />,
        }}
      />
    </>
  );
};

export default TransWithComponent;
