import { ListSummaryProps } from "./ListSummaryType";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import classnames from "classnames";
import CoverImage from "../../resources/background/bg02.png";
import Slider from "react-slick";
import ActionButton, { ActionButtonA } from "./ActionButton";
import SectionTitle from "./SectionTitle";

// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";

const ListSummarySlider = ({
  list,
  filters,
  className,
  emptyDataMessage,
}: ListSummaryProps) => {
  const { t } = useTranslation();
  const [catagory, setCategory] = useState(filters.at(0)?.name);
  const [currentIndex, setCurrentIndex] = useState(0);
  const sliderRef = useRef<Slider>(null);
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    className: "center",
    centerMode: true,
    afterChange: (current: number) => {
      setCurrentIndex(current);
    },
  };

  const onPrevClicked = () => {
    sliderRef.current?.slickPrev();
  };

  const onNextClicked = () => {
    sliderRef.current?.slickNext();
  };

  return (
    <div className={classnames("list-summary-slider", className)}>
      <div className="container">
        <div className="row justify-content-md-center">
          <div className="col-12">
            <ul className="filters liststyle mb--60">
              {filters.map((filter, i) => {
                const className = classnames({
                  active: catagory === filter.name,
                });
                return (
                  <li
                    className={className}
                    key={i}
                    onClick={() => setCategory(filter.name)}
                  >
                    <SectionTitle title={filter.title} />
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
      <div className="container-fuild">
        <div className="row justify-content-md-center">
          <div className="col-12">
            {list.length === 0 ? (
              <p className="text-white text-center pb--50">
                {emptyDataMessage}
              </p>
            ) : (
              <div className="summary-slider">
                <Slider ref={sliderRef} {...settings}>
                  {list.map((item, i) => (
                    <div className="slide-item" key={i}>
                      <div className="card">
                        <div className="plr--20 pt--20">
                          {item.coverImage && (
                            <div className="card-img-wrapper wrapper">
                              {item.coverImage}
                            </div>
                          )}
                          {/* {!item.coverImage && <div className="mt--50"></div>} */}
                        </div>

                        <div className="card-body">
                          {item.title && (
                            <h5 className="item-title">{item.title}</h5>
                          )}
                          {item.date && (
                            <div className="item-date">{item.date}</div>
                          )}
                          {item.excerpt && (
                            <div className="item-excerpt">{item.excerpt}</div>
                          )}
                        </div>

                        <div className="card-footer">
                          <ActionButtonA className="light" href={item.href!}>
                            {t("common.button.read-more")}
                          </ActionButtonA>
                        </div>
                      </div>
                    </div>
                  ))}
                </Slider>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row justify-content-md-center">
          <div className="col-12">
            {list.length > 1 && (
              <div className="pagination-container">
                <span className="page-prev" onClick={onPrevClicked}>
                  <FiChevronLeft color="#fff" />
                </span>
                <span className="page-current">{`${currentIndex + 1}/${
                  list.length
                }`}</span>
                <span className="page-next" onClick={onNextClicked}>
                  <FiChevronRight color="#fff" />
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListSummarySlider;
