import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import SectionTitle from "../../components/partial/SectionTitle";
import img01 from "../../resources/platforms/trading_central_install_01.png";
import img02 from "../../resources/platforms/trading_central_install_02.png";
import img03 from "../../resources/platforms/trading_central_install_03.png";
import img04 from "../../resources/platforms/trading_central_install_04.png";
import img05 from "../../resources/platforms/trading_central_install_05.png";

interface TradingCentralModalProps {
  isShow?: boolean;
  onClose?: (show: boolean) => void;
}

const TradingCentralModal = (props: TradingCentralModalProps) => {
  const { t, i18n } = useTranslation();
  const { isShow = false, onClose = () => {} } = props;

  const handleClose = () => {
    onClose(false);
  };

  useEffect(() => {
    if (isShow) {
      document.documentElement.style.overflow = "hidden";
    } else {
      document.documentElement.style.overflow = "auto";
    }
  }, [isShow]);

  return (
    <Modal
      show={isShow}
      onHide={handleClose}
      size="xl"
      centered
      className="tranding-central-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <SectionTitle title={t("trading_central.modal.header")} />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <section className="modal-section">
          <h4>{t("trading_central.modal.text.1")}</h4>
        </section>
        <section className="modal-section">
          <h4>{t("trading_central.modal.text.2")}</h4>
        </section>
        <section className="modal-section">
          <h4>{t("trading_central.modal.text.3")}</h4>
          <img
            className="mt--50"
            src={img01}
            alt={t("trading_central.modal.text.3")}
          />
        </section>
        <section className="modal-section">
          <h4>{t("trading_central.modal.text.4")}</h4>
          <div>
            <p>
              {t("trading_central.modal.text.5")}
              <br />
              {t("trading_central.modal.text.6")}
              <br />
              {t("trading_central.modal.text.7")}
              <br />
            </p>
          </div>
          <img
            className="mt--20"
            src={img02}
            alt={t("trading_central.modal.text.4")}
          />
        </section>
        <section className="modal-section">
          <h4>{t("trading_central.modal.text.8")}</h4>
          <img
            className="mt--30"
            src={img03}
            alt={t("trading_central.modal.text.8")}
          />
          <p className="ptb--10">{t("trading_central.modal.text.9")}</p>
          <img
            className=""
            src={img04}
            alt={t("trading_central.modal.text.8")}
          />
        </section>
        <section className="modal-section">
          <h4>{t("trading_central.modal.text.10")}</h4>
          <img
            className="mt--30"
            src={img05}
            alt={t("trading_central.modal.text.10")}
          />
        </section>
      </Modal.Body>
    </Modal>
  );
};

export default TradingCentralModal;
