import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import useFetch from "use-http";
import { LANGUAGES } from "../../constants";
import ActionButton from "../../components/partial/ActionButton";

const AnnouncementList = () => {
  const { t, i18n } = useTranslation();

  /**
   * announcement data
   */
  const [announcementData, setAnnouncementData] = useState<any>({
    announcements: [],
  });
  const { get, post, response, loading, error } = useFetch(
    process.env.REACT_APP_BACKEND_API_URL
  );

  // get announcements function
  const getAnnouncements = async () => {
    // get the language short code
    const languageCode =
      LANGUAGES.find((l) => l.locale === i18n.language)?.code || "tc";

    const results = await get(`/announcements/${languageCode}`);
    if (response.ok) setAnnouncementData(results);
  };

  useEffect(() => {
    getAnnouncements();
  }, [i18n.language]);

  return (
    <div className="announcement-list-wrapper ptb--150 ptb_sm--50">
      <div className="container">
        {announcementData.announcements.length === 0 ? (
          <div>{t("home.info.no-record.announcements")}</div>
        ) : (
          <div className="">
            {announcementData.announcements.map((a: any, i: number) => {
              const theDate = new Date(a.announcement_date);
              const dateString =
                ("0" + (theDate.getMonth() + 1)).slice(-2) +
                "/" +
                ("0" + theDate.getDate()).slice(-2);
              return (
                <div className="announcement-list-row" key={i}>
                  <div className="date">
                    <div className="year">{theDate.getFullYear()}</div>
                    <div className="month-day">{dateString}</div>
                    <div className="line-container">
                      <div className="c1"></div>
                      <div className="c2"></div>
                    </div>
                  </div>
                  <div className="cover-image">
                    {a.cover_image && (
                      <>
                        <div className="img-wrapper">
                          <img
                            className="img"
                            src={a.cover_image}
                            alt={a.title}
                          />
                          <div className="cover-image-title">
                            <h3>{a.title}</h3>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                  <div className="date-mobile">
                    <div className="year">{theDate.getFullYear()}</div>
                    <div className="month-day">{dateString}</div>
                  </div>
                  <div className="content">
                    <h3 className="title d-none d-lg-none d-xl-block">
                      {a.title}
                    </h3>
                    <div className="button-wrapper">
                      <ActionButton
                        className="light"
                        to={`/${i18n.language}/announcement_details/${a.id}`}
                      >
                        {t("common.button.read-more")}
                      </ActionButton>
                    </div>
                  </div>
                  <div className="separater"></div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default AnnouncementList;
