import { Trans, useTranslation } from "react-i18next";

const NotificationBar = () => {
  const { t } = useTranslation();

  return (
    <div className="notification-bar">
      <Trans
        i18nKey="notification.bar"
        components={{ italic: <i />, bold: <strong /> }}
      />
    </div>
  );
};

export default NotificationBar;
