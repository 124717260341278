import { useTranslation } from "react-i18next";
import PageHelment from "../../components/common/Helment";
import SectionTitle from "../../components/partial/SectionTitle";

const Disclaimer = () => {
  const { t } = useTranslation();
  return (
    <>
      <PageHelment
        title={`${t("common.meta.prefix")} | ${t(
          "legal.disclaimer.meta.title"
        )}`}
        description={t("legal.disclaimer.meta.description")}
      />
      <div className="legal-content pt--130 pb--150 pt_sm--60 pb_sm--75 pt_md--60 pb_md--75 pt_lg--60 pb_lg--75">
        <div className="container">
          <div className="text-left text-sm-center mb--60">
            <SectionTitle title={t("legal.disclaimer.page.title")} />
          </div>
          <section>
            <div className="mt--75 mt_sm--50 mt_md--50 mt_lg--50">
              <div>
                <p>{t("legal.disclaimer.content.text.1")}</p>
                <p>{t("legal.disclaimer.content.text.2")}</p>
                <p>{t("legal.disclaimer.content.text.3")}</p>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default Disclaimer;
