export const buildUrl = (url: string, lang: string) => {
  return ["#", ""].includes(url) ? "#" : `/${lang}${url}`;
};

export const isValidEmail = (email: string) => {
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return emailRegex.test(email);
};

export const isExternalUrl = (url?: string) => {
  if (url) {
    if (url === "") {
      return false;
    } else {
      return url.toString().startsWith("http");
    }
  }
  return false;
};

export const onlyLettersAndSpaces = (str: string) => {
  return /^[A-Za-z\s]*$/.test(str);
};

export const removeTags = (str: string) => {
  if (str === null || str === "") return "";
  else str = str.toString();

  // Regular expression to identify HTML tags in
  // the input string. Replacing the identified
  // HTML tag with a null string.
  return str.replace(/(<([^>]+)>)/gi, "");
};

export const getExcerpt = (str: string, count: number) => {
  if (str === null || str === "") return "";
  else str = str.toString();

  if (str.length <= count) return str;

  return str.slice(0, count) + "...";
};

export const isNumeric = (val: string): boolean => {
  return !isNaN(Number(val));
};

export const htmlEncode = (html: string) => {
  // create an element container
  var tempDiv = document.createElement("div");
  // put the string into the element as text context, and get the inner html
  tempDiv.textContent != undefined
    ? (tempDiv.textContent = html)
    : (tempDiv.innerText = html);
  var output = tempDiv.innerHTML;
  return output || "";
};

export const htmlDecode = (text: string) => {
  // create an element container
  var tempDiv = document.createElement("div");
  // put the string to the element as inner html
  tempDiv.innerHTML = text;
  // and get the inner text
  var output = tempDiv.innerText || tempDiv.textContent;
  return output || "";
};
